import {
  Collapse,
  createStyles,
  Grid,
  List,
  ListItem,
  makeStyles,
  Theme,
} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import React, { useState } from 'react';

import AdSetStats from '../ads/ad-set-stats';
import ListAd from '../ads/list-ad';
import AdsetMenuItems from '../shared/utils/menus/adset-menu-items';
import ContextMenu from '../shared/utils/menus/context-menu';
import AdSetActiveForm from './ad-set-active-form';
import { AdSet } from './queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: theme.spacing(4.75),
      display: 'flex',
    },
    header: {
      paddingLeft: theme.spacing(0),
    },
    nested: {
      paddingLeft: theme.spacing(0),
    },
    indent: {
      paddingLeft: theme.spacing(3),
    },
    flexAlign: {
      display: 'flex',
      alignItems: 'center',
    },
    dateIcon: {
      margin: theme.spacing(0, 1),
      opacity: 0.9,
      fontSize: theme.spacing(2.4),
    },
    rowIcon: {
      marginRight: theme.spacing(2),
      height: theme.spacing(2.875),
    },
    menuIcon: {
      marginRight: theme.spacing(1.2),
      fontSize: theme.spacing(2.6),
    },
  })
);

interface Props {
  adSet: AdSet;
  open?: boolean;
}

function AdSetItem({ adSet, open }: Props): JSX.Element {
  const classes = useStyles();
  const [listOpen, setListOpen] = useState(!!open);

  function handleListClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation();
    setListOpen(!listOpen);
  }

  return (
    <>
      <ContextMenu items={<AdsetMenuItems adSet={adSet} />}>
        <ListItem button onClick={handleListClick} className={classes.wrapper}>
          <Grid container alignItems="center" className={classes.header}>
            <Grid
              className={classes.indent}
              item
              xs={4}
              container
              alignItems="center"
            >
              <span className={classes.rowIcon}>
                {listOpen ? (
                  <DashboardOutlinedIcon opacity={adSet.active ? '1' : '0.3'} />
                ) : (
                  <DashboardIcon opacity={adSet.active ? '1' : '0.3'} />
                )}
              </span>
              <b>{adSet.name || adSet.id}</b>
            </Grid>

            <Grid
              item
              xs={1}
              onClick={(event): void => event.stopPropagation()}
            >
              {<AdSetActiveForm adSet={adSet} />}
            </Grid>

            <Grid item xs={3} className={classes.flexAlign}>
              <span className={classes.flexAlign}>
                {new Intl.DateTimeFormat('fr-FR').format(adSet.startDate)}
              </span>

              <ArrowRightIcon className={classes.dateIcon} />

              <span className={classes.flexAlign}>
                {new Intl.DateTimeFormat('fr-FR').format(adSet.endDate)}
              </span>
            </Grid>

            <Grid item xs={4}>
              <AdSetStats stats={adSet.stats} />
            </Grid>
          </Grid>
        </ListItem>
      </ContextMenu>

      <Collapse in={listOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div className={classes.nested}>
            <ListAd adSet={adSet} />
          </div>
        </List>
      </Collapse>
    </>
  );
}

export default AdSetItem;
