import gql from 'graphql-tag';

import { FilterSettings } from '../manager/filter-store';

export interface Client {
  id: string;
  externalPackageId: string;
  name: string;
  departments: string[];
}

export interface Campaign {
  id: string;
  name: string;
  clientId: string;
  objective?: string;

  capping: {
    type: CappingTypeEnum;
    amount?: number;
    budget?: number;
  };
}

export enum CappingTypeEnum {
  CPM = 'cpm',
  CPC = 'cpc',
  None = 'none',
}

export interface GetCampaignFilters {
  temporalities?: string;
  search?: string;
}

export type CampaignWithClient = Campaign & { client: Client };
export type CampaignWithoutId = Omit<Campaign, 'id'>;
export type CampaignInput = CampaignWithoutId;

const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    id
    name
    departments
    externalPackageId
  }
`;

const CAMPAIGN_FRAGMENT = gql`
  fragment CampaignFragment on Campaign {
    id
    name
    objective
    capping
    clientId @export(as: "clientId")
    client @rest(type: "Client", path: "/clients/{exportVariables.clientId}") {
      ...ClientFragment
    }
  }
  ${CLIENT_FRAGMENT}
`;

export interface GetClientsResult {
  clients: Client[];
}

export const GET_CLIENTS = gql`
  query GetClients {
    clients @rest(type: "[Client]", path: "/clients") {
      ...ClientFragment
    }
  }
  ${CLIENT_FRAGMENT}
`;

export interface GetCampaignsResult {
  campaigns: CampaignWithClient[];
}

export const GET_CAMPAIGNS = gql`
  query GetCampaigns($temporalities: String!, $search: String) {
    campaigns(temporalities: $temporalities, search: $search)
      @rest(type: "[Campaign]", path: "/campaigns?{args}") {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`;

export interface GetCampaignResult {
  campaign: CampaignWithClient;
}

export const GET_CAMPAIGN = gql`
  query GetCampaign($id: String!) {
    campaign(id: $id) @rest(type: "Campaign", path: "/campaigns/{args.id}") {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`;

export interface CreateCampaignParams {
  input: CampaignInput;
}

export interface CreateCampaignResult {
  campaign: { id: string };
}

export interface CreateClientParams {
  input: { name: string };
}

export interface CreateClientResult {
  client: { id: string };
}

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($input: CampaignInput!) {
    campaign(input: $input)
      @rest(type: "Campaign", path: "/campaigns", method: "POST") {
      id
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation CreateClient($input: CreateClientParams!) {
    client(input: $input)
      @rest(type: "Client", path: "/clients", method: "POST") {
      id
    }
  }
`;

export interface SaveCampaignParams {
  id: string;
  input: CampaignInput;
}

export const SAVE_CAMPAIGN = gql`
  mutation SaveCampaign($id: String!, $input: CampaignInput!) {
    campaign(id: $id, input: $input)
      @rest(type: "Campaign", path: "/campaigns/{args.id}", method: "PUT") {
      id
    }
  }
`;

export interface DeleteCampaignParams {
  id: string;
}

export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($id: String!) {
    campaign(id: $id)
      @rest(type: "Campaign", path: "/campaigns/{args.id}", method: "DELETE") {
      id
    }
  }
`;

export function buildGetCampaignQuery(
  filters: FilterSettings
): GetCampaignFilters {
  const variables: GetCampaignFilters = {};

  if (filters.temporalities.length > 0) {
    variables.temporalities = filters.temporalities.join(',');
  }
  if (filters.search) {
    variables.search = filters.search;
  }
  return variables;
}
