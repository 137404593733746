import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import {
  useEditingActions,
  useEditingState,
} from '../../../manager/editing-store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(2),
    },
    wrapper: {
      marginTop: theme.spacing(2),
    },
  })
);

function CreateAdFormControls(): JSX.Element {
  const classes = useStyles();

  const setReOpen = useEditingActions((actions) => actions.setReOpen);
  const formDisabled = useEditingState((state) => state.loading);

  const setEditAfterCreation = useEditingActions(
    (actions) => actions.setEditAfterCreation
  );

  function setFlags(reOpen: boolean, editAfterCreation: boolean): void {
    setReOpen(reOpen);
    setEditAfterCreation(editAfterCreation);
  }

  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.button}
        color="secondary"
        variant="contained"
        disabled={formDisabled}
        type="submit"
        onClick={(): void => {
          setFlags(false, false);
        }}
      >
        Créer
      </Button>

      <Button
        className={classes.button}
        color="secondary"
        variant="outlined"
        disabled={formDisabled}
        type="submit"
        onClick={(): void => {
          setFlags(true, false);
        }}
      >
        Créer et continuer
      </Button>

      <Button
        className={classes.button}
        color="secondary"
        disabled={formDisabled}
        variant="outlined"
        type="submit"
        onClick={(): void => {
          setFlags(false, true);
        }}
      >
        Créer et éditer
      </Button>
    </div>
  );
}

export default CreateAdFormControls;
