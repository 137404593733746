import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';

import TextInput from '../inputs/text-input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
  })
);

function JobNativeSidebarAdGroup(): JSX.Element {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="companyName">Nom de l&apos;entreprise</InputLabel>

        <TextInput name="content.companyName" required={true} />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="title">Titre</InputLabel>

        <TextInput name="content.title" required={true} />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="description">Description</InputLabel>

        <TextInput name="content.description" required={true} />
      </FormControl>
    </div>
  );
}

export default JobNativeSidebarAdGroup;
