import { useMutation, useQuery } from '@apollo/react-hooks';
import React from 'react';

import { useEditingActions } from '../manager/editing-store';
import AdForm from './forms/ad-form';
import {
  AdWithoutIds,
  GET_AD,
  GET_ADS_BY_ADSET_ID,
  GetAdResult,
  SAVE_AD,
  SaveAdParams,
} from './queries';

interface Props {
  id: string;
}

function ModifyAd(props: Props): JSX.Element | null {
  const { id } = props;
  const stopEditing = useEditingActions((actions) => actions.stopEditing);
  const setLoading = useEditingActions((actions) => actions.setLoading);

  const { data } = useQuery<GetAdResult>(GET_AD, {
    variables: { id },
  });

  const [saveAd] = useMutation<void, SaveAdParams>(SAVE_AD, {
    refetchQueries: [
      {
        query: GET_ADS_BY_ADSET_ID,
        variables: { adSetId: data && data.ad.adSetId },
      },
    ],
  });

  async function handleSubmit(input: AdWithoutIds): Promise<void> {
    if (!data) {
      return;
    }

    setLoading(true);

    await saveAd({
      variables: {
        id,
        input: {
          adSetId: data.ad.adSetId,
          active: data.ad.active,
          ...input,
        },
      },
    })
      .then(() => {
        stopEditing();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return data ? <AdForm ad={data.ad} onSubmit={handleSubmit} /> : null;
}

export default ModifyAd;
