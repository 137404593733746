import { useEffect } from 'react';

import { useGlobalState } from '../../store';
import { isExpired, refreshAuth } from '../utils/auth';

export function useAuth(): boolean[] {
  const isLoggedIn = useGlobalState(({ isLoggedIn }) => isLoggedIn);
  const decodedToken = useGlobalState(({ decodedToken }) => decodedToken);

  // Check if token has expired on each render, in case of navigation
  const shouldRefreshAuth = isExpired(decodedToken);

  useEffect(() => {
    if (shouldRefreshAuth) {
      refreshAuth().catch(() => {});
    }
  }, [shouldRefreshAuth]);

  return [shouldRefreshAuth, isLoggedIn];
}
