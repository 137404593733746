import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CampaignWithClient,
  CampaignWithoutId,
  CappingTypeEnum,
} from '../queries';
import CappingTypeSelect from './capping-type-select';
import CentsInput from './cents-input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '50%',
      '&:first-child': {
        marginRight: theme.spacing(1),
      },
    },
    fullInput: {
      flex: '1 1 auto',
      marginBottom: theme.spacing(1),
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);

interface Props {
  campaign?: CampaignWithClient;
}

export default function CappingInput(props: Props): JSX.Element {
  const classes = useStyles();

  const { campaign } = props;

  const { watch } = useFormContext<CampaignWithoutId>();

  const type = watch('capping.type') as CappingTypeEnum | undefined;

  return (
    <>
      <div className={classes.fullInput}>
        <CappingTypeSelect disabled={!!campaign} name="capping.type" />
      </div>

      {type && type !== CappingTypeEnum.None && (
        <div className={classes.wrapper}>
          <div className={classes.input}>
            <CentsInput
              name="capping.budget"
              disabled={!!campaign}
              inputProps={{ min: 0 }}
              label="Budget"
            />
          </div>

          <div className={classes.input}>
            <CentsInput
              name="capping.amount"
              disabled={!!campaign}
              inputProps={{ min: 0, step: 0.1 }}
              label={`Valeur du ${type.toUpperCase()}`}
            />
          </div>
        </div>
      )}
    </>
  );
}
