import React, { useEffect, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { logInFromSso } from '../shared/utils/auth';

export enum LoginStateEnum {
  NoLoginToken = 'NoLoginToken',
  LoggingIn = 'LoggingIn',
  Success = 'Success',
  Error = 'Error',
}

function Login(): JSX.Element {
  const { search } = useLocation();
  const loginToken = new URLSearchParams(search).get('login-token');

  const [state, setState] = useState<LoginStateEnum>(
    loginToken ? LoginStateEnum.LoggingIn : LoginStateEnum.NoLoginToken
  );

  useEffect(() => {
    if (!loginToken) {
      return;
    }

    logInFromSso(loginToken)
      .then(() => {
        setState(LoginStateEnum.Success);
      })
      .catch(() => {
        setState(LoginStateEnum.Error);
      });
  }, [loginToken]);

  switch (state) {
    case LoginStateEnum.LoggingIn:
      return <p>Connexion en cours...</p>;

    case LoginStateEnum.Error:
      return (
        <p>
          Erreur de connexion - <Link to="/">Retour à la page d’accueil</Link>
        </p>
      );

    default:
      return <Redirect to="/" />;
  }
}

export default Login;
