import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import MouseIcon from '@material-ui/icons/Mouse';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useMemo } from 'react';

import { Stats } from '../ad-sets/queries';
import { formatBigValue } from '../shared/utils/utils';
import StatLabel from './stats/stat-label';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adWrapper: {
      color: theme.palette.grey['600'],
    },
    adSetWrapper: {},
  })
);

interface Props {
  stats: Stats[];
}

function buildStatsView(stats: Stats[]): StatsView {
  const viewsCount = stats.reduce((prev, cur) => {
    return prev + cur.views;
  }, 0);
  const clicksCount = stats.reduce((prev, cur) => {
    return prev + cur.clicks;
  }, 0);
  const ratio = (clicksCount / viewsCount) * 100;

  return {
    views: {
      value: viewsCount,
      format: formatBigValue(viewsCount),
    },
    clicks: {
      value: clicksCount,
      format: formatBigValue(clicksCount),
    },
    ratio: {
      value: ratio,
      format: `${ratio.toFixed(2)}%`,
    },
  };
}

interface StatsView {
  views: {
    format: string;
    value: number;
  };
  clicks: {
    format: string;
    value: number;
  };
  ratio: {
    format: string;
    value: number;
  };
}

export default function AdSetStats({ stats }: Props): JSX.Element {
  const classes = useStyles();

  const view = useMemo(() => buildStatsView(stats), [stats]) as StatsView;

  return (
    <Grid container className={classes.adSetWrapper}>
      <Grid item xs={4}>
        <StatLabel
          value={view.views.format}
          title={`${view.views.value}`}
          showIcon={true}
          icon={<VisibilityIcon />}
        />
      </Grid>
      <Grid item xs={4}>
        <StatLabel
          value={view.clicks.format}
          title={`${view.clicks.value}`}
          showIcon={true}
          icon={<MouseIcon />}
        />
      </Grid>
      <Grid item xs={4}>
        <StatLabel
          value={view.ratio.format}
          title={`${view.ratio.value}`}
          showIcon={true}
          icon={<DataUsageIcon />}
        />
      </Grid>
    </Grid>
  );
}
