import React from 'react';
import { useFormContext } from 'react-hook-form';

import { AdSet, LocalisationZoneEnum } from '../queries';

const labelByLocalisation: Record<LocalisationZoneEnum, string> = {
  [LocalisationZoneEnum.Client]: 'Départements du client',
  [LocalisationZoneEnum.Custom]: 'Personnalisée',
  [LocalisationZoneEnum.France]: 'Toute la france',
  [LocalisationZoneEnum.International]: 'Internationale',
};

export default function LocalisationSummary(): JSX.Element {
  const { watch } = useFormContext<AdSet>();

  const localisation = watch(
    'criteria.localisation.zone'
  ) as LocalisationZoneEnum;

  return <span>{labelByLocalisation[localisation]}</span>;
}
