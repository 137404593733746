import { useMutation, useQuery } from '@apollo/react-hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { FilterOptionsState } from '@material-ui/lab';
import Autocomplete, {
  createFilterOptions,
  RenderInputParams,
} from '@material-ui/lab/Autocomplete';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useEditingActions } from '../../manager/editing-store';
import {
  CampaignWithClient,
  CampaignWithoutId,
  Client,
  CREATE_CLIENT,
  CreateClientParams,
  CreateClientResult,
  GET_CLIENTS,
  GetClientsResult,
} from '../queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
  })
);

const filter = createFilterOptions();

interface Props {
  campaign?: CampaignWithClient;
}

function formatClientName(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export default function ClientsInput(props: Props): JSX.Element {
  const classes = useStyles();

  const { campaign } = props;

  const name = 'clientId';
  const [disabled, setDisabled] = useState(false);
  const setLoading = useEditingActions((actions) => actions.setLoading);

  const { loading: loadingClientsData, data: clientsData } = useQuery<
    GetClientsResult
  >(GET_CLIENTS);

  const [createClient] = useMutation<CreateClientResult, CreateClientParams>(
    CREATE_CLIENT,
    {
      refetchQueries: [
        {
          query: GET_CLIENTS,
        },
      ],
    }
  );

  const { register, unregister, setValue } = useFormContext<
    CampaignWithoutId
  >();

  useEffect(() => {
    register({ name }, { required: true });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  async function registerClient(newClient: string): Promise<void> {
    setDisabled(true);
    setLoading(true);

    const { data: result } = await createClient({
      variables: {
        input: { name: formatClientName(newClient) },
      },
    });

    if (result) {
      await setValue(name, result.client.id);
    } else {
      await setValue(name, '');
    }
    setDisabled(false);
    setLoading(false);
  }

  async function handleChange(
    event: ChangeEvent<{}>,
    newValue: Client | null
  ): Promise<void> {
    if (!newValue) {
      // field has been cleared
      await setValue(name, '');
    }

    if (newValue && !newValue.id) {
      // field contains new client
      await registerClient(newValue.name);
    }

    if (newValue && newValue.id) {
      // field contains existing client (default behaviour)
      await setValue(name, newValue.id);
    }
  }

  function getOptionLabel(client: Client): string {
    if (!client.id) {
      return client.name;
    }
    return client.id;
  }

  return (
    <Autocomplete
      className={classes.input}
      disabled={disabled}
      loading={loadingClientsData}
      defaultValue={campaign && campaign.client}
      onChange={handleChange}
      filterOptions={(
        options,
        params: FilterOptionsState<Client>
      ): Client[] => {
        const filtered = filter(
          options,
          params as FilterOptionsState<unknown>
        ) as Client[];

        if (params.inputValue !== '') {
          filtered.push({
            name: params.inputValue,
            id: '',
            externalPackageId: '',
            departments: [],
          });
        }

        return filtered;
      }}
      options={clientsData ? clientsData.clients : []}
      getOptionLabel={getOptionLabel}
      renderOption={(client: Client): string =>
        client.id ? `${client.name} (${client.id})` : `Ajouter "${client.name}"`
      }
      renderInput={(params: RenderInputParams): JSX.Element => (
        <TextField {...params} fullWidth variant="filled" label="Client" />
      )}
    />
  );
}
