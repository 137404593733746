/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

import { platform } from '../environment';
import { store } from '../store';

class Sidebar extends React.Component<{}, { isLoggedIn: boolean }> {
  unsubscribe: Function;
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };

    this.unsubscribe = store.subscribe(() => {
      this.setState({
        ...this.state,
        isLoggedIn: store.getState().isLoggedIn,
      });
    });
  }

  componentDidMount() {
    store.getState().isLoggedIn &&
      this.setState({
        ...this.state,
        isLoggedIn: store.getState().isLoggedIn,
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render(): JSX.Element | null {
    return this.state.isLoggedIn ? (
      <wiz-sidebar environment={platform}></wiz-sidebar>
    ) : null;
  }
}

export default Sidebar;
