import { useQuery } from '@apollo/react-hooks';
import { Box, createStyles, List, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import AdSetItem from './ad-set-item';
import {
  GET_ADSETS_BY_CAMPAIGN_ID,
  GetAdSetsByCampainIdResult,
} from './queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listWrapper: {
      width: '100%',
      padding: 0,
      margin: 0,
      backgroundColor: theme.palette.background.paper,
    },
    empty: {
      margin: theme.spacing(2, 5, 2, 0),
      paddingLeft: theme.spacing(3),
    },
  })
);

interface Props {
  campaignId: string;
}

function ListAdSet({ campaignId }: Props): JSX.Element {
  const classes = useStyles();

  const { data } = useQuery<GetAdSetsByCampainIdResult>(
    GET_ADSETS_BY_CAMPAIGN_ID,
    { variables: { campaignId } }
  );

  if (!data) {
    return <></>;
  }

  const { adSets } = data;

  return (
    <List className={classes.listWrapper}>
      {(adSets.length === 0 && (
        <Box
          fontSize={14}
          fontWeight={500}
          fontFamily="Roboto"
          className={classes.empty}
        >
          <em>
            Il n&apos;existe pas encore d&apos;ensembles d&apos;annonces dans
            cette campagne
          </em>
        </Box>
      )) ||
        adSets.map((adSet) => (
          <AdSetItem open={adSets.length === 1} key={adSet.id} adSet={adSet} />
        ))}
    </List>
  );
}

export default ListAdSet;
