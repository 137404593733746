import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';

import { useEditingState } from '../../../manager/editing-store';
import AdStatsModal from '../../ad-stats-modal';
import { Ad, AdContentTypeEnum } from '../../queries';
import PreviewButton from '../inputs/preview-button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonSpacer: {
      marginRight: theme.spacing(2),
      marginLeft: 'auto',
    },
    submitButton: {
      width: '120px',
    },
    wrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

interface Props {
  ad: Ad<AdContentTypeEnum>;
}

function EditAdFormControls(props: Props): JSX.Element {
  const { ad } = props;
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const formDisabled = useEditingState((state) => state.loading);

  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.submitButton}
        color="secondary"
        type="submit"
        variant="contained"
        disabled={formDisabled}
      >
        Modifier
      </Button>

      <div className={classes.buttonSpacer}>
        <PreviewButton ad={ad} />
      </div>

      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={(): void => {
          setOpenModal(true);
        }}
      >
        Voir les stats
      </Button>

      <AdStatsModal
        adId={ad.id}
        open={openModal}
        onCloseHandler={(): void => {
          setOpenModal(false);
        }}
      />
    </div>
  );
}

export default EditAdFormControls;
