import 'date-fns'; // eslint-disable-line
import React, { useEffect } from 'react';
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { AdSet } from '../queries';
import { LabelByLang, LangEnum } from '../../shared/utils/lang';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: theme.spacing(1),
    },
    title: {
      fontWeight: 500,
      fontSize: theme.spacing(2.1),
      marginBottom: theme.spacing(0.8),
    },
    inputs: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
  })
);

interface Props {
  name: string;
}

function LanguageInput(props: Props): JSX.Element {
  const { register, unregister, setValue, watch } = useFormContext<AdSet>();

  const { name } = props;

  const classes = useStyles();

  const languages = (watch(name) || []) as string[];

  useEffect(() => {
    register({ name });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  function handleChange(lang: string): void {
    if (languages.includes(lang)) {
      setValue(
        name,
        languages.filter((l) => l !== lang)
      );
    } else {
      setValue(name, [...languages, lang]);
    }
  }

  return (
    <FormControl className={classes.wrapper}>
      <Typography className={classes.title}>
        Langage de l&apos;utilisateur
      </Typography>

      <FormGroup className={classes.inputs}>
        {Object.values(LangEnum).map((lang) => (
          <FormControlLabel
            key={lang}
            control={
              <Checkbox
                checked={languages.includes(lang)}
                onChange={(): void => handleChange(lang)}
              />
            }
            label={LabelByLang[lang]}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export default LanguageInput;
