import { Menu, MenuItem, withStyles } from '@material-ui/core';
import React, { useState } from 'react';

interface Props {
  children: JSX.Element;
  items: JSX.Element;
}

const initialContextMenuState = { mouseX: null, mouseY: null };

export const ContextMenuItem = withStyles((theme) => ({
  root: {
    fontSize: theme.spacing(1.7),
    paddingLeft: theme.spacing(1.5),
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
    },
  },
}))(MenuItem);

function ContextMenu(props: Props): JSX.Element {
  const { children, items } = props;

  const [state, setState] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialContextMenuState);

  const handleContextMenuClick = (
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleContextMenuClose = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setState(initialContextMenuState);
  };

  return (
    <div onContextMenu={handleContextMenuClick}>
      {children}

      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        {React.cloneElement(items, {
          closeMenu: handleContextMenuClose,
        })}
      </Menu>
    </div>
  );
}

export default ContextMenu;
