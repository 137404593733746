import { apiDomain, appId, ssoRedirectUrl } from '../../environment';
import { Jwt, JwtTokens, store } from '../../store';
import { fetchJson } from './utils';

const AUTH_API_DOMAIN = `https://auth.${apiDomain}/v1`;

export function navigateToSso(): void {
  const params = new URLSearchParams([
    ['redirect_url', ssoRedirectUrl],
    ['appId', appId],
  ]);

  window.location.assign(
    `https://sso.internal.${apiDomain}/?${params.toString()}`
  );
}

export async function logInFromSso(loginToken: string): Promise<void> {
  return fetchJson<JwtTokens>(`${AUTH_API_DOMAIN}/auth/login-token`, {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ appId, loginToken }),
  }).then((tokens) => {
    store.getActions().logIn(tokens);
  });
}

export function isExpired(decodedToken?: Jwt): boolean {
  return !!decodedToken && decodedToken.exp * 1000 <= Date.now() + 10000; // add ten seconds to account for potential latency
}

export async function refreshAuth(): Promise<void> {
  const { token, refreshToken } = store.getState();

  return fetchJson<JwtTokens>(`${AUTH_API_DOMAIN}/jwt/refresh`, {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ appId, token, refreshToken }),
  })
    .then((tokens) => {
      store.getActions().logIn(tokens);
    })
    .catch(() => {
      store.getActions().logOut(); // log out completely
    });
}
