import gql from 'graphql-tag';

import { LangEnum } from '../shared/utils/lang';

export interface LoyaltyPackageIdCriteria {
  none: boolean;
  client: boolean;
  other: boolean;
}

export interface Criteria {
  loyaltyPackageId: LoyaltyPackageIdCriteria;
  localisation: LocalisationCriteria;
  age?: { min: number; max: number };
  banks: BankCriteria;
  drivingLicense?: boolean;
  student?: boolean;
  studentLoan?: boolean;
  mutualHealth?: boolean;
  userLanguages?: LangEnum[];
}

export enum LocalisationZoneEnum {
  Client = 'Client',
  France = 'France',
  Custom = 'Custom',
  International = 'International',
}

export enum PriorityEnum {
  Master = 'Master',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Default = 'Default',
}

export interface BankCriteria {
  substractedBanks?: string[];
}

export interface LocalisationCriteria {
  zone: LocalisationZoneEnum;
  addedDepartments?: string[];
  substractedDepartments?: string[];
  countries?: string[];
}

export interface AdSet {
  id: string;
  campaignId: string;
  name?: string;
  startDate: Date;
  endDate: Date;
  priority: PriorityEnum;
  criteria: Criteria;
  active: boolean;
  stats: Stats[];
}

export interface Stats {
  adId: string;
  views: number;
  clicks: number;
}

export type AdSetWithoutIds = Omit<AdSet, 'id' | 'campaignId'>;

export type AdSetInput = Omit<AdSet, 'id' | 'startDate' | 'endDate'> & {
  startDate: string;
  endDate: string;
};

export interface GetAdSetResult {
  adSet: AdSet;
}

const STATS_FRAGMENT = gql`
  fragment StatsFragment on Stats {
    adId
    views
    clicks
  }
`;

export const ADSET_FRAGMENT = gql`
  fragment AdSetFragment on AdSet {
    id @export(as: "adSetId")
    campaignId
    name
    startDate
    endDate
    priority
    active
    criteria {
      loyaltyPackageId
      age
      localisation {
        zone
        addedDepartments
        substractedDepartments
        countries
      }
      banks
      drivingLicense
      student
      studentLoan
      mutualHealth
      userLanguages
    }
  }
`;

export const GET_ADSET = gql`
  query GetAdSet($id: String!) {
    adSet(id: $id) @rest(type: "AdSet", path: "/ad_sets/{args.id}") {
      id @export(as: "adSetId")
      ...AdSetFragment
      stats
        @rest(
          type: "[Stats]"
          path: "/ad_sets/{exportVariables.adSetId}/stats"
        ) {
        ...StatsFragment
      }
    }
  }
  ${ADSET_FRAGMENT}
  ${STATS_FRAGMENT}
`;

export interface GetAdSetsByCampainIdResult {
  adSets: AdSet[];
}

export const GET_ADSETS_BY_CAMPAIGN_ID = gql`
  query GetAdSetsByCampainId($campaignId: String!) {
    adSets(campaignId: $campaignId)
      @rest(type: "[AdSet]", path: "/ad_sets?campaignIds={args.campaignId}") {
      id @export(as: "adSetId")
      campaignId
      name
      startDate
      endDate
      priority
      active
      criteria {
        loyaltyPackageId
        age
        localisation {
          zone
          addedDepartments
          substractedDepartments
          countries
        }
        banks
        drivingLicense
        student
        studentLoan
        mutualHealth
        userLanguages
      }
      stats
        @rest(
          type: "[Stats]"
          path: "/ad_sets/{exportVariables.adSetId}/stats"
        ) {
        ...StatsFragment
      }
    }
  }
  ${STATS_FRAGMENT}
`;

export interface CreateAdSetParams {
  input: AdSetInput;
}

export interface CreateAdSetResult {
  adSet: { id: string };
}

export interface SaveAdSetParams {
  id: string;
  input: AdSetInput;
}

export interface EditAdSetParams {
  id: string;
  input: Partial<AdSet>;
}

export const CREATE_ADSET = gql`
  mutation CreateAdSet($input: AdSetInput!) {
    adSet(input: $input)
      @rest(type: "AdSet", path: "/ad_sets", method: "POST") {
      id
    }
  }
`;

export const SAVE_ADSET = gql`
  mutation SaveAdSet($id: String!, $input: AdSetInput!) {
    adSet(id: $id, input: $input)
      @rest(type: "AdSet", path: "/ad_sets/{args.id}", method: "PUT") {
      id
    }
  }
`;

export const EDIT_ADSET = gql`
  mutation EditAdSet($id: String!, $input: AdSetInput!) {
    adSet(id: $id, input: $input)
      @rest(type: "AdSet", path: "/ad_sets/{args.id}", method: "PATCH") {
      id
    }
  }
`;

export interface DeleteAdSetParams {
  id: string;
}

export const DELETE_ADSET = gql`
  mutation DeleteAdSet($id: String!) {
    adSet(id: $id)
      @rest(type: "AdSet", path: "/ad_sets/{args.id}", method: "DELETE") {
      id
    }
  }
`;
