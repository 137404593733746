import { createStyles, makeStyles, Theme } from '@material-ui/core';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { SpotColor } from '../../ads/ad-item';
import { SpotEnum } from '../../ads/queries';
import { CalendarEvent } from '../inventory';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customRbcEvent: {
      color: theme.palette.primary.main,
      marginLeft: '0.312rem',
      padding: '.1rem 0',
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      margin: '0 .5rem 0 1rem',
      display: 'flex',
      alignItems: 'center',
    },
    resource: {
      padding: '.2rem .3rem .1rem',
      borderRadius: '.3rem',
      cursor: 'pointer',
      fontWeight: 'bolder',
      fontSize: '.7rem',
    },
    info: {
      margin: '0',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  })
);

// BigCalendar refuse typed extension on Agenda & Day view
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomAgendaEvent(props: any): JSX.Element | null {
  const { event } = props as { event: CalendarEvent; title: string };
  const classes = useStyles();
  const history = useHistory();

  function handleRedirection(id: string, type: string): void {
    history.push(`/inventory/?id=${id}&type=${type}`);
  }

  return (
    <div className={classes.customRbcEvent}>
      <span
        style={{ backgroundColor: SpotColor[event.resourceId as SpotEnum] }}
        className={classes.resource}
        onClick={(): void => handleRedirection(event.id, 'Ad')}
      >
        {event.resourceId}
      </span>

      <i className={classes.title}>Priorité:</i>
      <p className={classes.info}>{event.priority}</p>

      <i className={classes.title}>
        <DashboardOutlinedIcon />
        &nbsp;Ensemble:
      </i>
      <p
        onClick={(): void => handleRedirection(event.adSetId, 'AdSet')}
        className={classes.info}
      >
        {event.adSetId}
      </p>

      <i className={classes.title}>
        <FolderOpenIcon />
        &nbsp;Campagne:{' '}
      </i>
      <p
        onClick={(): void => handleRedirection(event.campaignId, 'Campaign')}
        className={classes.info}
      >
        {event.campaign}
      </p>
    </div>
  );
}
