import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';

import { Ad, AdContentTypeEnum, SpotEnum } from '../../queries';
import DropMediaInput from '../inputs/drop-media-input';
import TextInput from '../inputs/text-input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
  })
);

interface Props {
  ad?: Ad<AdContentTypeEnum>;
  spot: SpotEnum;
}

function MenuEntryGroup(props: Props): JSX.Element {
  const { ad, spot } = props;
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="title">Titre</InputLabel>
        <TextInput name="content.title" />
      </FormControl>

      <DropMediaInput required={false} spot={(ad && ad.spot) || spot} />
    </div>
  );
}

export default MenuEntryGroup;
