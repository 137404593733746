import { ApolloProvider } from '@apollo/react-hooks';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { apolloClient } from './apollo-client';
import Root from './core/root';
import { apiPlatform, platform } from './environment';

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);

if (platform !== 'prod') {
  // eslint-disable-next-line no-console
  console.dir({ platform, apiPlatform });
}
