import { useMutation } from '@apollo/react-hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { forwardRef, Ref } from 'react';

import {
  Ad,
  AdContentTypeEnum,
  DELETE_AD,
  DeleteAdParams,
  GET_ADS_BY_ADSET_ID,
} from '../../../ads/queries';
import { ContextMenuItem } from './context-menu';

interface Props {
  ad: Ad<AdContentTypeEnum>;
  adSetId: string;
  closeMenu?: (e: React.MouseEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuIcon: {
      marginRight: theme.spacing(1.2),
      fontSize: theme.spacing(2.6),
    },
  })
);

const AdMenuItems = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { ad, adSetId, closeMenu } = props;

  const classes = useStyles();

  const [deleteAd] = useMutation<void, DeleteAdParams>(DELETE_AD, {
    variables: { id: ad.id },
    refetchQueries: [
      {
        query: GET_ADS_BY_ADSET_ID,
        variables: { adSetId },
      },
    ],
  });

  const handleClose = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    if (closeMenu) {
      closeMenu(event);
    }
  };

  async function handleDeletion(
    event: React.MouseEvent<HTMLElement>
  ): Promise<void> {
    handleClose(event);
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Êtes-vous sûr(e) de vraiment vouloir supprimer la publicité ?'
      )
    ) {
      await deleteAd();
    }
  }

  return (
    <div ref={ref}>
      <ContextMenuItem onClick={handleDeletion}>
        <DeleteIcon className={classes.menuIcon} />
        Supprimer la publicité
      </ContextMenuItem>
    </div>
  );
});

export default AdMenuItems;
