import {
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { AdSet } from '../../ad-sets/queries';
import { Ad, AdContentTypeEnum } from '../../ads/queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      marginLeft: -theme.spacing(1),
    },
  })
);

interface Props {
  name: string;
  label: string;
  disabled: boolean;
}

function CheckboxInput(props: Props): JSX.Element {
  const { name, label, disabled } = props;
  const classes = useStyles();

  const {
    setValue,
    register,
    unregister,
    watch,
    formState,
    triggerValidation,
  } = useFormContext<Ad<AdContentTypeEnum> | AdSet>();

  useEffect(() => {
    register({ name });
    return (): void => unregister([name]);
  }, [name, register, unregister]);

  const value = watch(name) as boolean | null;

  async function handleChange(name: string, checked: boolean): Promise<void> {
    await setValue(name, checked);

    if (formState.isSubmitted) {
      await triggerValidation(name);
    }
  }

  return (
    <FormControlLabel
      className={classes.label}
      labelPlacement="end"
      control={
        <Checkbox
          name={name}
          disabled={disabled}
          onChange={async (
            _: ChangeEvent<HTMLInputElement>,
            checked: boolean
          ): Promise<void> => handleChange(name, checked)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          checked={value || false}
        />
      }
      label={label}
    />
  );
}

export default CheckboxInput;
