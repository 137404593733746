import React from 'react';
import { useFormContext } from 'react-hook-form';

import { AdSet, PriorityEnum } from '../queries';

const labelByPriority: Record<PriorityEnum, string> = {
  [PriorityEnum.Master]: 'Master',
  [PriorityEnum.High]: 'Haute',
  [PriorityEnum.Medium]: 'Moyenne',
  [PriorityEnum.Low]: 'Faible',
  [PriorityEnum.Default]: 'Par défaut',
};

export default function PrioritySummary(): JSX.Element {
  const { watch } = useFormContext<AdSet>();

  const priority = watch('priority') as PriorityEnum;

  return <span>{labelByPriority[priority]}</span>;
}
