import { useQuery } from '@apollo/react-hooks';
import { Box, createStyles, List, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { AdSet } from '../ad-sets/queries';
import AdItem from './ad-item';
import { GET_ADS_BY_ADSET_ID, GetAdsByAdSetIdResult } from './queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      paddingTop: 0,
      paddingLeft: theme.spacing(3),
    },
    listWrapper: {
      width: '100%',
      padding: 0,
      margin: 0,
      backgroundColor: theme.palette.background.paper,
    },
    empty: {
      margin: theme.spacing(2, 8, 2, 3),
      paddingLeft: theme.spacing(3),
    },
  })
);

interface Props {
  adSet: AdSet;
}

function ListAd({ adSet }: Props): JSX.Element {
  const classes = useStyles();

  const { data } = useQuery<GetAdsByAdSetIdResult>(GET_ADS_BY_ADSET_ID, {
    variables: { adSetId: adSet.id },
  });

  if (!data) {
    return <></>;
  }

  const { ads } = data;

  return (
    <List component="ul" className={classes.listWrapper}>
      {(ads.length === 0 && (
        <Box
          fontSize={14}
          fontWeight={500}
          fontFamily="Roboto"
          className={classes.empty}
        >
          <em>Il n&apos;existe pas encore de publicité dans cet ensemble</em>
        </Box>
      )) ||
        ads.map((ad) => (
          <AdItem
            key={ad.id}
            ad={ad}
            adSetId={adSet.id}
            active={adSet.active}
            stats={adSet.stats.find((r) => r.adId === ad.id)}
          />
        ))}
    </List>
  );
}

export default ListAd;
