import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';

import MediaInput from '../inputs/media-input';
import TextInput from '../inputs/text-input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
  })
);

function ArticleGroup(): JSX.Element {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="title">Titre</InputLabel>

        <TextInput name="content.title" required={true} />
      </FormControl>

      <MediaInput name="content.logoUrl" label="Logo" required={true} />

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="introduction">Introduction</InputLabel>
        <TextInput name="content.introduction" multiline={true} />
      </FormControl>

      <MediaInput name="content.bannerUrl" label="Bannière" required={false} />

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="body">Corps</InputLabel>
        <TextInput name="content.body" required={true} multiline={true} />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="legals">Conditions d&apos;utilisation</InputLabel>
        <TextInput name="content.legals" multiline={true} />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="buttonText">Texte du bouton</InputLabel>
        <TextInput name="content.buttonText" required={true} />
      </FormControl>
    </div>
  );
}

export default ArticleGroup;
