import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { createImageElem } from '../../../shared/utils/file';
import { SpotEnum } from '../../queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16,
    },
    fileSize: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      display: 'inline-block',
    },
    previewTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(0.5),
      fontSize: '14px',
      color: theme.palette.grey[500],
    },
    preview: {
      top: '-1px',
      right: 'calc(100% + 1px)',
      position: 'absolute',
      padding: theme.spacing(3),
      background: '#222222',
      height: '100%',
    },
    errorMessage: {
      color: theme.palette.secondary.main,
    },
    size: {
      color: theme.palette.grey[500],
    },
  })
);

interface Props {
  inputValue: string;
  ratio: number;
  spot?: SpotEnum;
}

export function Thumb(props: Props): JSX.Element {
  const { inputValue, ratio, spot } = props;

  const classes = useStyles();

  const [img, setImg] = useState<HTMLImageElement>();

  useEffect(() => {
    if (!inputValue) {
      return;
    }

    createImageElem(inputValue)
      .then(setImg)
      .catch(() => {});
  }, [inputValue]);

  if (!inputValue) {
    return <></>;
  }

  const imgDimensions = img;

  return (
    <aside className={classes.thumbsContainer}>
      <div className={classes.preview}>
        <h3 className={classes.previewTitle}>Prévisualisation du media</h3>

        <div className={classes.fileSize}>
          {/*<!-- Find a way to get filesize from url
        {filePreview && (
          <span className={classes.size}>
            {filePreview.file.size / 1000}&nbsp;ko&nbsp;—&nbsp;
          </span>
        )}
          */}

          {imgDimensions && (
            <>
              <span className={classes.size}>
                Ratio{' '}
                {Math.round(
                  (imgDimensions.width / imgDimensions.height) * 100
                ) / 100}
              </span>{' '}
              {Math.abs(ratio - imgDimensions.width / imgDimensions.height) >
                0.1 && (
                <span className={classes.errorMessage}>
                  &nbsp;({ratio} attendu)
                </span>
              )}
            </>
          )}
        </div>

        <img
          alt=""
          style={
            spot === SpotEnum.VerticalBannerLaRuche ||
            SpotEnum.VerticalBannerWizbii
              ? { maxWidth: '333px', height: 'auto', maxHeight: '70vh' }
              : spot === SpotEnum.BannerLaRuche || SpotEnum.BannerWizbii
              ? { maxWidth: '40vw', maxHeight: '70vh' }
              : { width: `${200 * ratio}px`, height: '200px' }
          }
          src={inputValue}
        />
      </div>
    </aside>
  );
}
