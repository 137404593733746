import { createStyles, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Client } from '../../campaigns/queries';
import { AdSet } from '../queries';

const useStyles = makeStyles(
  createStyles({
    labels: {
      position: 'relative',

      '& span:after': {
        content: ', ',
        position: 'absolute',
        left: '0',
        top: '0',
        width: '1rem',
        height: '1rem',
        background: 'red',
      },
    },
  })
);

interface Props {
  client: Client | null;
}

function getLabel(external: boolean, loyalty: Record<string, boolean>): string {
  const noneValue = loyalty.none;
  const clientValue = loyalty.client;
  const otherValue = loyalty.other;

  const values = [];

  noneValue && values.push('Non-loyalty');
  clientValue && external && values.push('Loyalty du client');
  otherValue && values.push('Loyalty non-client');

  const maxChoices = external ? 3 : 2;

  if (values.length === 0 || values.length === maxChoices) {
    return 'Tout le monde';
  }

  return values.join(', ');
}

export default function LoyaltySummary(props: Props): JSX.Element {
  const { client } = props;
  const classes = useStyles();

  const { watch } = useFormContext<AdSet>();

  const loyalty = watch('criteria.loyaltyPackageId') as Record<string, boolean>;
  const hasExternalPackageId = !!(client && !!client.externalPackageId);

  const label = useMemo(() => getLabel(hasExternalPackageId, loyalty), [
    loyalty,
    hasExternalPackageId,
  ]);

  return <span className={classes.labels}>{label}</span>;
}
