import { createStyles, makeStyles } from '@material-ui/core';
import RowingIcon from '@material-ui/icons/Rowing';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '3rem',
      textAlign: 'center',
    },
  })
);

function NotFound(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1 className="heading-1">
        <RowingIcon fontSize="inherit" /> Oups <RowingIcon fontSize="inherit" />
      </h1>
      <p className="text-large">Cette page n’existe pas.</p>
    </div>
  );
}

export default NotFound;
