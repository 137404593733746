import { useMutation } from '@apollo/react-hooks';
import { FormGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';

import ActiveInput from '../shared/inputs/active-input';
import {
  AdSet,
  EDIT_ADSET,
  EditAdSetParams,
  GET_ADSETS_BY_CAMPAIGN_ID,
} from './queries';

interface Props {
  adSet: AdSet;
}

export default function AdSetActiveForm(props: Props): JSX.Element {
  const { adSet } = props;

  const formMethods = useForm<AdSet>({ defaultValues: adSet });
  const { setValue, getValues } = formMethods;

  const [disabled, setDisabled] = useState(false);

  const [editAdSet] = useMutation<void, EditAdSetParams>(EDIT_ADSET, {
    refetchQueries: [
      {
        query: GET_ADSETS_BY_CAMPAIGN_ID,
        variables: { campaignId: adSet.campaignId },
      },
    ],
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setValue('active', adSet.active);
  }, [adSet.active, setValue]);

  async function changeValue(): Promise<void> {
    setDisabled(true);

    const { active } = getValues();

    await editAdSet({
      variables: {
        id: adSet.id,
        input: {
          active,
        },
      },
    });

    setDisabled(false);
  }

  return (
    <FormContext {...formMethods}>
      <form>
        <FormGroup>
          <ActiveInput
            color={'secondary'}
            handleClick={changeValue}
            small={true}
            disabled={disabled}
            name="active"
          />
        </FormGroup>
      </form>
    </FormContext>
  );
}
