import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Logo } from '../images/logo.svg';
import { navigateToSso } from '../shared/utils/auth';
import { useGlobalActions, useGlobalState } from '../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      flex: '1 1 0%',
      marginTop: '-0.25rem',
    },
    title: {
      margin: 0,
      color: 'white',
      fontSize: '1.1rem',
      lineHeight: 1,
    },
    subTitle: {
      margin: 0,
    },
    menuButton: {
      position: 'relative',
      marginLeft: 'auto',
      padding: '0 0.4rem',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      fontSize: '2rem',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '0.5rem',
    },
    links: {
      color: theme.palette.primary.light,
      marginRight: theme.spacing(2),
      textDecoration: undefined,
    },
    linkActive: {
      color: theme.palette.secondary.main,
    },
    logo: {
      width: '4.1em',
      height: 'auto',
      marginRight: '0.5rem',
    },
    login: {
      marginRight: theme.spacing(1),
    },
  })
);

function Header(): JSX.Element {
  const classes = useStyles();
  const isLoggedIn = useGlobalState(({ isLoggedIn }) => isLoggedIn);
  const logOut = useGlobalActions(({ logOut }) => logOut);

  return (
    <AppBar position="static">
      <Toolbar>
        <Logo className={classes.logo} />

        <div className={classes.heading}>
          <span className={classes.subTitle}>Wizbii Tools</span>
          <h1 className={classes.title}>Ad Manager</h1>
        </div>

        <div className={classes.login}>
          {isLoggedIn ? (
            <div>
              <NavLink
                className={classes.links}
                to="/"
                exact
                activeClassName={classes.linkActive}
              >
                Dashboard
              </NavLink>

              <NavLink
                className={classes.links}
                to="/inventory"
                exact
                activeClassName={classes.linkActive}
              >
                Inventaire
              </NavLink>

              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={(): void => logOut()}
              >
                Déconnexion
              </Button>
            </div>
          ) : (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={navigateToSso}
            >
              Connexion
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
