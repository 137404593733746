import { createStyles, makeStyles, Theme } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import React from 'react';

import { SpotColor } from '../../ads/ad-item';
import { SpotEnum } from '../../ads/queries';
import { CalendarEvent } from '../inventory';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customRbcEvent: {
      color: theme.palette.primary.main,
      marginLeft: '0.312rem',
      paddingTop: '0.625rem',
    },
    info: {
      margin: '0',
      textOverflow: 'ellipsis',
      lineHeight: '1.25rem',
      wordWrap: 'break-word',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      padding: 0,
    },
    title: {
      margin: '0.312rem 0 0',
      fontSize: '80%',
      textOverflow: 'ellipsis',
      lineHeight: '0.625rem',
      wordWrap: 'break-word',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    name: {
      display: 'flex',
      alignItems: 'center',
      margin: '0.312rem 0 1rem',
      fontSize: '100%',
      textOverflow: 'ellipsis',
      lineHeight: '0.625rem',
      wordWrap: 'break-word',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  })
);

// BigCalendar refuse typed extension on Agenda & Day view
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomDayEvent(props: any): JSX.Element | null {
  const { event } = props as { event: CalendarEvent; title: string };
  const classes = useStyles();

  return (
    <div className={classes.customRbcEvent}>
      <b className={classes.name}>
        <ImageIcon style={{ color: SpotColor[event.resourceId as SpotEnum] }} />
        {event.title}
      </b>

      <i className={classes.title}>Priorité:</i>
      <p className={classes.info}>{event.priority}</p>

      <i className={classes.title}>Campagne:</i>
      <p className={classes.info}>{event.campaign}</p>

      <i className={classes.title}>Ensemble:</i>
      <p className={classes.info}>{event.adSetId}</p>
    </div>
  );
}
