import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: '140px',
    },
    value: {
      textAlign: 'right',
      width: '50%',
    },
    icon: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      textAlign: 'right',
      '& > *': {
        fontSize: theme.spacing(2),
      },
    },
  })
);

interface Props {
  value: string | number;
  icon?: JSX.Element;
  showIcon?: boolean;
  title?: string;
}

export default function StatLabel(props: Props): JSX.Element {
  const { value, icon, showIcon, title } = props;

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <span className={[classes.icon].join(' ')}>{showIcon && icon}</span>
      <span className={classes.value} title={title}>
        {value}
      </span>
    </div>
  );
}
