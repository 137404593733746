import {
  Button,
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';

import { usePreviewActions } from '../../../manager/preview-store';
import { getImageInfo, uploadFile } from '../../../shared/utils/file';
import { Ad, AdContentTypeEnum, SpotEnum } from '../../queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16,
    },
    container: {},
    thumb: {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      width: 400,
      height: 200,
      padding: 4,
      boxSizing: 'border-box',
    },
    thumbInner: {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden',
    },
    img: {
      flex: 1,
      display: 'block',
      width: 'auto',
      height: '100%',
    },
    dropZone: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
      borderWidth: '2px',
      borderRadius: '2px',
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
    },
    ratio: {
      display: 'block',
      textAlign: 'right',
      marginTop: theme.spacing(1),
    },
  })
);

export interface SelectedFile {
  file: File;
  width: number;
  height: number;
  preview: string;
}

export const RatioPerSpot = {
  [SpotEnum.VerticalBannerLaRuche]: 0.7,
  [SpotEnum.VerticalBannerWizbii]: 0.7,
  [SpotEnum.BannerWizbii]: 3.76,
  [SpotEnum.BannerLaRuche]: 2.64,
  [SpotEnum.JobNativeAdWizbii]: 1,
  [SpotEnum.JobNativeSidebarAdWizbii]: 2.09,
  [SpotEnum.MenuEntryWizbii]: 1,
  [SpotEnum.HelpNativeAdFibii]: 3,
  [SpotEnum.LandingWizbii]: 3,
  [SpotEnum.TopBarAdWizbii]: 1,
};

interface Props {
  spot: SpotEnum;
  required: boolean;
}

function DropMediaInput(props: Props): JSX.Element {
  const { spot, required } = props;

  const ratio = RatioPerSpot[spot];

  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const showPreview = usePreviewActions((actions) => actions.showPreview);

  const {
    setValue,
    register,
    unregister,
    triggerValidation,
    formState,
    watch,
  } = useFormContext<Ad<AdContentTypeEnum>>();

  const inputValue = watch('content.img') as string;

  useEffect(() => {
    register({ name: 'content.img', required });
    register({ name: 'content.width', required });

    return (): void => unregister(['content.img', 'content.width']);
  }, [register, unregister, required]);

  useEffect(() => {
    if (inputValue) {
      setTimeout(() => {
        showPreview(inputValue);
      }, 250);
    }
  }, [inputValue, showPreview]);

  async function onDrop(files: File[]): Promise<void> {
    const file = files[0];
    setLoading(true);

    const { width } = await getImageInfo(file);

    await setValue('content.width', width);

    const publicUrl = await uploadFile(file);

    await setValue('content.img', publicUrl);

    if (formState.isSubmitted) {
      await triggerValidation('content.img');
    }

    setLoading(false);

    showPreview(publicUrl);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDropAccepted: onDrop,
    multiple: false,
  });

  return (
    <section className={classes.container}>
      <div className={classes.dropZone} {...getRootProps()}>
        <input {...getInputProps()} />

        <Button
          disabled={loading}
          type="button"
          variant="outlined"
          color="secondary"
        >
          {inputValue ? 'Remplacer le media' : 'Ajouter un media'}
        </Button>
      </div>

      {loading && <LinearProgress color="secondary" />}

      <em className={classes.ratio}>Ratio attendu : {ratio}</em>
    </section>
  );
}

export default DropMediaInput;
