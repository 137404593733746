import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { SpotEnum } from '../../ads/queries';

type MUIAutocompleteChangeEvent = ChangeEvent<{}>;

function SpotAutocomplete(): JSX.Element {
  const name = 'spot';
  const ALL_SPOTS = useMemo(() => Object.keys(SpotEnum), []);

  const {
    register,
    unregister,
    setValue,
    triggerValidation,
    formState,
    watch,
  } = useFormContext();

  const spots = ((watch(name) || []) as string[]).map((spotInput) => {
    return ALL_SPOTS.find((spot) => spot === spotInput);
  }) as string[];

  useEffect(() => {
    register({ name });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  async function handleChange(
    _: MUIAutocompleteChangeEvent,
    value: string[]
  ): Promise<void> {
    await setValue(
      name,
      value.map((val) => val)
    );

    if (formState.isSubmitted) {
      await triggerValidation(name);
    }
  }

  return (
    <Autocomplete
      multiple
      id={`${name}-tags-outlined`}
      options={ALL_SPOTS}
      getOptionLabel={(spot: string): string => spot}
      value={spots}
      filterSelectedOptions
      onChange={handleChange}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          variant="outlined"
          label="Emplacements"
          margin="normal"
          fullWidth
        />
      )}
    />
  );
}
export default SpotAutocomplete;
