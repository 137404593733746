import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  ButtonGroup,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { fr } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';

interface Props {
  onNavigate: Function;
  onView: Function;
  date: Date;
  label: string;
  view: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.25rem 0 2rem',
    },
    label: {
      textTransform: 'capitalize',
      fontWeight: 'bolder',
      fontSize: '15px',
      marginRight: theme.spacing(4),
    },
    labelWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      height: theme.spacing(5),
    },
  })
);

export function CustomToolBar(props: Props): JSX.Element | null {
  const { onNavigate, onView, view, date: calendarDate } = props;
  const classes = useStyles();

  const [date, setDate] = useState();

  function handleDateChange(e: MaterialUiPickersDate): void {
    onNavigate('day', e);
  }

  useEffect(() => {
    setDate(calendarDate);
  }, [calendarDate]);

  return (
    <div className={classes.toolBar}>
      <ButtonGroup color="secondary" aria-label="outlined primary button group">
        <Button
          variant="outlined"
          color="secondary"
          onClick={(): void => onNavigate('PREV')}
        >
          <NavigateBeforeIcon />
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          onClick={(): void => onNavigate('TODAY')}
        >
          <span>Aujourd&apos;hui</span>
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          onClick={(): void => onNavigate('NEXT')}
        >
          <NavigateNextIcon />
        </Button>
      </ButtonGroup>

      <div className={classes.labelWrapper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
          <KeyboardDatePicker
            id="start-date"
            name="startDate"
            format="dd/MM/yyyy"
            margin="normal"
            value={date}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </div>

      <ButtonGroup color="secondary" aria-label="outlined primary button group">
        <Button
          variant={view === 'month' ? 'contained' : 'outlined'}
          color="secondary"
          onClick={(): void => onView('month')}
        >
          Mois
        </Button>

        <Button
          variant={view === 'day' ? 'contained' : 'outlined'}
          color="secondary"
          onClick={(): void => onView('day')}
        >
          Jour
        </Button>

        <Button
          variant={view === 'agenda' ? 'contained' : 'outlined'}
          color="secondary"
          onClick={(): void => onView('agenda')}
        >
          Agenda
        </Button>
      </ButtonGroup>
    </div>
  );
}
