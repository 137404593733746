import { useMutation } from '@apollo/react-hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { forwardRef, Ref } from 'react';

import {
  buildGetCampaignQuery,
  CampaignWithClient,
  DELETE_CAMPAIGN,
  DeleteCampaignParams,
  GET_CAMPAIGNS,
} from '../../../campaigns/queries';
import {
  ResourceTypeEnum,
  useEditingActions,
} from '../../../manager/editing-store';
import { useFilterState } from '../../../manager/filter-store';
import { ContextMenuItem } from './context-menu';

interface Props {
  campaign: CampaignWithClient;
  closeMenu?: (e: React.MouseEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuIcon: {
      marginRight: theme.spacing(1.2),
      fontSize: theme.spacing(2.6),
    },
  })
);

const CampaignMenuItems = forwardRef(
  (props: Props, ref: Ref<HTMLDivElement>) => {
    const { campaign, closeMenu } = props;

    const classes = useStyles();
    const startEditing = useEditingActions((actions) => actions.startEditing);
    const filterSettings = useFilterState((state) => state.currentSettings);

    const [deleteCampaign] = useMutation<void, DeleteCampaignParams>(
      DELETE_CAMPAIGN,
      {
        variables: { id: campaign.id },
        refetchQueries: [
          {
            query: GET_CAMPAIGNS,
            variables: buildGetCampaignQuery(filterSettings),
          },
        ],
      }
    );

    const handleClose = (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
      if (closeMenu) {
        closeMenu(event);
      }
    };

    async function handleDeletion(
      event: React.MouseEvent<HTMLElement>
    ): Promise<void> {
      handleClose(event);
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          'Êtes-vous sûr(e) de vraiment vouloir supprimer la campagne ?'
        )
      ) {
        await deleteCampaign();
      }
    }

    return (
      <div ref={ref}>
        <ContextMenuItem
          onClick={(event): void => {
            handleClose(event);
            startEditing({
              type: ResourceTypeEnum.Campaign,
              id: campaign.id,
            });
          }}
        >
          <EditIcon className={classes.menuIcon} />
          Éditer la campagne
        </ContextMenuItem>

        <ContextMenuItem
          onClick={(event): void => {
            handleClose(event);
            startEditing({
              type: ResourceTypeEnum.AdSet,
              parentId: campaign.id,
            });
          }}
        >
          <DashboardIcon className={classes.menuIcon} />
          Ajouter un ensemble
        </ContextMenuItem>

        <ContextMenuItem onClick={handleDeletion}>
          <DeleteIcon className={classes.menuIcon} />
          Supprimer la campagne
        </ContextMenuItem>
      </div>
    );
  }
);

export default CampaignMenuItems;
