import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import {
  FilterSettings,
  TemporalityEnum,
  useFilterActions,
  useFilterState,
} from '../../manager/filter-store';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: theme.spacing(1.8),
  },
}));

export default function TemporalityFilter(): JSX.Element {
  const { temporalities }: FilterSettings = useFilterState(
    (state) => state.currentSettings
  );
  const setTemporalities = useFilterActions(
    (actions) => actions.setTemporalities
  );

  const [state, setState] = React.useState<Record<TemporalityEnum, boolean>>({
    [TemporalityEnum.Past]: temporalities.includes(TemporalityEnum.Past),
    [TemporalityEnum.Current]: temporalities.includes(TemporalityEnum.Current),
    [TemporalityEnum.Future]: temporalities.includes(TemporalityEnum.Future),
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const newState = { ...state, [event.target.value]: event.target.checked };
    setState(newState);

    const tempo = Object.keys(newState).filter(
      (tmp) => newState[tmp as TemporalityEnum]
    );
    setTemporalities(tempo as TemporalityEnum[]);
  }

  const classes = useStyles();
  const { past, current, future } = state;

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={past}
            value="past"
            onChange={handleChange}
          />
        }
        label={<span className={classes.label}>Passées</span>}
      />

      <FormControlLabel
        control={
          <Checkbox value="current" checked={current} onChange={handleChange} />
        }
        label={<span className={classes.label}>En cours</span>}
      />

      <FormControlLabel
        control={
          <Checkbox value="future" checked={future} onChange={handleChange} />
        }
        label={<span className={classes.label}>À venir</span>}
      />
    </>
  );
}
