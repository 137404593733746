import 'react-big-calendar/lib/css/react-big-calendar.css';
import './react-big-calendar.css';

import { useQuery } from '@apollo/react-hooks';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
// eslint-disable-next-line @typescript-eslint/camelcase
import date_fns from 'date-fns/locale/fr';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { parse as qsParse } from 'query-string';
import React, { useMemo } from 'react';
import { Calendar, dateFnsLocalizer, Event } from 'react-big-calendar';
import { FormContext, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Ad, AdContentTypeEnum, SpotEnum } from '../ads/queries';
import {
  StartEditingPayload,
  useEditingActions,
} from '../manager/editing-store';
import { FilterSettings, TemporalityEnum } from '../manager/filter-store';
import { useAuth } from '../shared/hooks/useAuth';
import { CalendarSkeleton } from './custom-views/calendar-skeleton';
import { CustomAgendaEvent } from './custom-views/custom-agenda-event';
import { CustomDayEvent } from './custom-views/custom-day-event';
import { CustomEvent } from './custom-views/custom-event';
import { CustomRessourceHeader } from './custom-views/custom-ressource-header';
import { CustomToolBar } from './custom-views/custom-tool-bar';
import SpotAutocomplete from './custom-views/spot-autocomplete';
import {
  buildGetAllQuery,
  GET_ALL,
  GetAllResult,
  resourceMap,
} from './queries';

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    fr: date_fns,
  },
});

export interface CalendarEvent extends Event {
  id: string;
  adSetId: string;
  campaign: string;
  campaignId: string;
  priority: string;
  resourceId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: theme.spacing(6),
    },
    title: {
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
  })
);

export function Inventory(): JSX.Element | null {
  const classes = useStyles();
  const history = useHistory();
  const [shouldRefreshAuth, isLoggedIn] = useAuth();

  const queryParams = qsParse(window.location.search);
  const startEditing = useEditingActions((actions) => actions.startEditing);

  if (queryParams.type && (queryParams.id || queryParams.parentId)) {
    setTimeout(() => {
      startEditing(queryParams as StartEditingPayload);
    }, 500);
  }

  const temps: FilterSettings = {
    temporalities: [TemporalityEnum.Current, TemporalityEnum.Future],
  };

  const formMethods = useForm({
    defaultValues: [],
  });

  const { watch } = formMethods;
  const spotInputValue = watch('spot') as SpotEnum[] | undefined;

  const { data: campaignResult, loading } = useQuery<GetAllResult>(GET_ALL, {
    variables: buildGetAllQuery(temps),
  });

  function shoudlDisplayAd(
    ad: Ad<AdContentTypeEnum>,
    spotInputValue: SpotEnum[]
  ): boolean {
    const spotMatches =
      spotInputValue.includes(ad.spot) || spotInputValue.length === 0;
    return ad.active && spotMatches;
  }

  const calendarEvents = useMemo(() => {
    const ev: CalendarEvent[] = [];

    if (campaignResult) {
      campaignResult.campaigns.forEach((campaign) => {
        campaign.adSets.forEach((adSet) => {
          if (adSet.active) {
            adSet.ads.forEach((ad) => {
              if (shoudlDisplayAd(ad, spotInputValue || [])) {
                ev.push({
                  id: ad.id,
                  adSetId: adSet.id,
                  title: ad.name,
                  priority: adSet.priority.toString(),
                  campaign: campaign.name.toString(),
                  campaignId: campaign.id,
                  start: adSet.startDate,
                  end: adSet.endDate,
                  resourceId: ad.spot,
                });
              }
            });
          }
        });
      });
    }

    return ev;
  }, [campaignResult, spotInputValue]);

  function onDoubleClickEvent(event: CalendarEvent): void {
    history.push(`/inventory/?id=${event.adSetId}&type=AdSet`);
  }

  const computedRessourceMap = useMemo(() => {
    if (spotInputValue && spotInputValue.length > 0) {
      return resourceMap.filter(
        (ressource) =>
          spotInputValue.includes(ressource.resourceId) || spotInputValue === []
      );
    }

    return resourceMap;
  }, [spotInputValue]);

  if (shouldRefreshAuth) {
    return <p>Reconnexion en cours...</p>;
  }

  if (!isLoggedIn) {
    return <p>Vous êtes déconnecté.</p>;
  }

  if (loading) {
    return (
      <Grid className={classes.wrapper}>
        <CalendarSkeleton />
      </Grid>
    );
  }

  return (
    <Grid className={classes.wrapper}>
      <h2 className={classes.title}>Inventaire</h2>

      <FormContext {...formMethods}>
        <form>
          <SpotAutocomplete />
        </form>
      </FormContext>

      <Calendar
        events={calendarEvents}
        defaultView={'agenda'}
        views={{ month: true, day: true, agenda: true }}
        culture={'fr'}
        step={30}
        timeslots={6}
        components={{
          event: CustomEvent,
          resourceHeader: CustomRessourceHeader,
          toolbar: CustomToolBar,
          day: {
            event: CustomDayEvent,
          },
          agenda: {
            event: CustomAgendaEvent,
          },
        }}
        showMultiDayTimes
        startAccessor={'start'}
        endAccessor="end"
        localizer={localizer}
        resources={computedRessourceMap}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceTitle"
        onDoubleClickEvent={onDoubleClickEvent}
        messages={{
          allDay: 'Toute la journée',
          time: 'Horaire',
          event: 'Publicité',
        }}
        formats={{
          dateFormat: 'dd',
          dayFormat: 'cccc dd/MM',
          weekdayFormat: 'cccc',
          timeGutterFormat: 'p',
          monthHeaderFormat: 'MMMM yyyy',
          dayHeaderFormat: 'cccc dd MMM',
          agendaDateFormat: 'cccc dd MMM',
          agendaTimeFormat: 'p',
        }}
      />
    </Grid>
  );
}
