import { Container, createMuiTheme, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { StoreProvider } from 'easy-peasy';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import { version } from '../environment';
import { Inventory } from '../inventory/inventory';
import EditingDrawer from '../manager/editing-drawer';
import { EditingStoreProvider } from '../manager/editing-store';
import { FilterStoreProvider } from '../manager/filter-store';
import Manager from '../manager/manager';
import { PreviewStoreProvider } from '../manager/preview-store';
import { store } from '../store';
import Header from './header';
import Login from './login';
import NotFound from './not-found';
import Sidebar from './sidebar';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#010029',
      light: '#e9e8f0',
    },
    secondary: {
      light: '#ff8b8e',
      main: '#fe5c61',
      contrastText: '#ffffff',
    },
  },
  overrides: {
    MuiDrawer: {
      paperAnchorRight: {
        overflow: 'visible',
        width: '50vw',
        right: '0',
        left: 'auto',
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        top: '50%',
        left: '0',
        right: 'auto',
        position: 'absolute',
        transform: 'translateY(-50%)',
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: 'rgba(254, 92, 97, 0.3)',
      },
    },
  },
});

function Root(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/_internal/status">
        {version}
      </Route>
      <Route path="*">
        <Helmet>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        {/* Styles */}
        <CssBaseline />
        <ThemeProvider theme={theme}>
          {/* Stores */}
          <StoreProvider store={store}>
            <EditingStoreProvider>
              <FilterStoreProvider>
                <Header />

                <Container maxWidth="xl">
                  <PreviewStoreProvider>
                    <EditingDrawer />
                  </PreviewStoreProvider>
                  <Sidebar />
                  <Switch>
                    <Route path="/" exact>
                      <Manager />
                    </Route>
                    <Route path="/login" exact>
                      <Login />
                    </Route>
                    <Route exact path="/inventory">
                      <Inventory />
                    </Route>
                    <Route path="*">
                      <NotFound />
                    </Route>
                  </Switch>
                </Container>
              </FilterStoreProvider>
            </EditingStoreProvider>
          </StoreProvider>
        </ThemeProvider>
      </Route>
    </Switch>
  );
}

export default Root;
