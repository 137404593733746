import { SpotEnum } from '../../ads/queries';

export enum SitesUrl {
  Fibii = 'https://fibii.co',
  LaRuche = 'https://laruche.wizbii.com',
  Wizbii = 'https://wizbii.com',
}

export const previewUrlPerSpot: Record<
  SpotEnum,
  { path: string; infos?: string }
> = {
  [SpotEnum.VerticalBannerLaRuche]: { path: SitesUrl.LaRuche },
  [SpotEnum.BannerLaRuche]: {
    path: SitesUrl.LaRuche,
    infos: 'Sur mobile uniquement',
  },
  [SpotEnum.VerticalBannerWizbii]: {
    path: `${SitesUrl.Wizbii}/search/jobs`,
    infos: 'Non-authentifié',
  },
  [SpotEnum.BannerWizbii]: {
    path: `${SitesUrl.Wizbii}/search/jobs`,
    infos: 'Sélectionner un Job',
  },
  [SpotEnum.JobNativeAdWizbii]: { path: `${SitesUrl.Wizbii}/search/jobs` },
  [SpotEnum.JobNativeSidebarAdWizbii]: {
    path: `${SitesUrl.Wizbii}/search/jobs`,
  },
  [SpotEnum.TopBarAdWizbii]: {
    path: `${SitesUrl.Wizbii}/search/jobs`,
  },
  [SpotEnum.MenuEntryWizbii]: { path: `${SitesUrl.Wizbii}/search/jobs` },
  [SpotEnum.LandingWizbii]: { path: `${SitesUrl.Wizbii}/wizmail` },
  [SpotEnum.HelpNativeAdFibii]: {
    path: `${SitesUrl.Fibii}/dashboard`,
    infos: 'Authentification requise',
  },
};
