import { useQuery } from '@apollo/react-hooks';
import { Box, createStyles, List, makeStyles, Theme } from '@material-ui/core';
import { parse } from 'query-string';
import React, { useEffect } from 'react';

import {
  StartEditingPayload,
  useEditingActions,
} from '../manager/editing-store';
import { useFilterState } from '../manager/filter-store';
import CampaignItem from './campaign-item';
import {
  buildGetCampaignQuery,
  GET_CAMPAIGNS,
  GetCampaignsResult,
} from './queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      borderColor: theme.palette.grey[300],
      borderWidth: '.062rem',
      borderStyle: 'solid',
      borderRadius: '.25rem',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    listWrapper: {
      width: '100%',
      padding: 0,
      margin: 0,
      backgroundColor: theme.palette.background.paper,
    },
    empty: {
      padding: theme.spacing(2, 5, 2, 0),
      paddingLeft: theme.spacing(3),
      backgroundColor: theme.palette.grey[100],
    },
  })
);

function ListCampain(): JSX.Element {
  const classes = useStyles();

  const filterSettings = useFilterState((state) => state.currentSettings);
  const startEditing = useEditingActions((actions) => actions.startEditing);

  const { data, refetch } = useQuery<GetCampaignsResult>(GET_CAMPAIGNS, {
    variables: buildGetCampaignQuery(filterSettings),
  });

  const queryParams = parse(window.location.search);

  if (queryParams.type && (queryParams.id || queryParams.parentId)) {
    setTimeout(() => {
      startEditing(queryParams as StartEditingPayload);
    }, 500);
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch().catch();
  }, [filterSettings, refetch]);

  if (!data) {
    return <></>;
  }

  const { campaigns } = data;

  return (
    <div className={classes.wrapper}>
      <List component="ul" className={classes.listWrapper}>
        {(campaigns.length === 0 && (
          <Box
            fontSize={14}
            fontWeight={500}
            fontFamily="Roboto"
            className={classes.empty}
          >
            <em>Il n&apos;y a aucune campagne</em>
          </Box>
        )) ||
          campaigns.map((campaign) => (
            <CampaignItem key={campaign.id} campaign={campaign} />
          ))}
      </List>
    </div>
  );
}

export default ListCampain;
