import 'date-fns'; // eslint-disable-line
import React, { ChangeEvent, useEffect } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { Ad, AdContentTypeEnum } from '../../ads/queries';

interface Props {
  name: string;
  small?: boolean;
  disabled?: boolean;
  handleClick?: () => void;
  required?: boolean;
  color: 'primary' | 'secondary' | 'default' | undefined;
  label?: string[];
}

function ActiveInput(props: Props): JSX.Element {
  const { name, small, disabled, handleClick, required, color, label } = props;

  const {
    register,
    unregister,
    setValue,
    triggerValidation,
    formState,
    watch,
  } = useFormContext<Ad<AdContentTypeEnum>>();

  const value = watch(name) as boolean;

  useEffect(() => {
    register({ name }, { required });
    return (): void => unregister([name]);
  }, [register, unregister, name, required]);

  async function handleChange(
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    await setValue(name, checked);

    if (handleClick) {
      handleClick();
    }

    if (formState.isSubmitted) {
      await triggerValidation(name);
    }
  }

  return (
    <FormControlLabel
      control={
        <Switch
          size={small ? 'small' : 'medium'}
          name={name}
          color={color}
          checked={value || false}
          onChange={handleChange}
          disabled={disabled}
        />
      }
      label={<span>{value ? label && label[0] : label && label[1]}</span>}
    />
  );
}

export default ActiveInput;
