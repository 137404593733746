import { FilledInput, InputAdornment } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CampaignWithoutId } from '../queries';

interface Props {
  name: string;
  disabled?: boolean;
  label: string;
  inputProps?: {};
}

function centsToEuro(value: string): string {
  return (parseFloat(value) / 100).toString();
}
function euroToCents(value: string): number {
  return Math.round(parseFloat(value) * 100);
}

export default function CentsInput(props: Props): JSX.Element {
  const { name, disabled, label, inputProps } = props;

  const { watch, setValue, register, unregister } = useFormContext<
    CampaignWithoutId
  >();

  const rawValue = watch(name) as string;

  const [inputValue, setInputValue] = useState<string>(centsToEuro(rawValue));

  useEffect(() => {
    register({ name }, { required: true });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  async function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): Promise<void> {
    setInputValue(event.target.value);
    const transformedValue = euroToCents(event.target.value);
    await setValue(name, transformedValue);
  }

  return (
    <>
      <FormControl fullWidth variant="filled" disabled={disabled}>
        <InputLabel>{label}</InputLabel>
        <FilledInput
          inputProps={inputProps}
          type="number"
          value={inputValue}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          onChange={handleChange}
          name={name}
        />
      </FormControl>
    </>
  );
}
