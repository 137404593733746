import {
  Button,
  createStyles,
  FilledInput,
  FormControl,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';

import { useEditingActions, useEditingState } from '../manager/editing-store';
import { preventEnterSubmit } from '../shared/utils/utils';
import CappingInput from './inputs/capping-input';
import ClientsInput from './inputs/clients-input';
import {
  CampaignWithClient,
  CampaignWithoutId,
  CappingTypeEnum,
} from './queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
    wrapper: {
      padding: theme.spacing(5),
      overflowY: 'scroll',
    },
    box: {
      marginBottom: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    button: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
    spacer: {
      margin: theme.spacing(5, 0),
    },
  })
);

const defaultValues = {
  name: '',
  capping: {
    type: CappingTypeEnum.None,
    amount: 0,
    budget: 0,
  },
};

interface Props {
  campaign?: CampaignWithClient;
  onSubmit(values: CampaignWithoutId, wizardMode: boolean): Promise<void>;
}

export default function CampaignForm(props: Props): JSX.Element {
  const classes = useStyles();
  const { campaign, onSubmit } = props;
  const [wizardMode, setWizardMode] = useState(false);

  const formMethods = useForm<CampaignWithoutId>({
    defaultValues: campaign || defaultValues,
  });

  const { register, handleSubmit, formState } = formMethods;

  const setDirty = useEditingActions((actions) => actions.setDirty);

  const formDisabled = useEditingState((state) => state.loading);

  useEffect(() => {
    if (formState.dirty) {
      setDirty();
    }
  }, [formState, setDirty]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant={'h5'} className={classes.input}>
          Campagne
        </Typography>
        <Typography color={'textSecondary'} className={classes.box}>
          Définir les paramètres de la campagne de publicités
        </Typography>
      </div>

      <FormContext {...formMethods}>
        <form
          className={classes.box}
          onSubmit={handleSubmit(async (input) => onSubmit(input, wizardMode))}
          noValidate
          onKeyPress={preventEnterSubmit}
        >
          <FormControl className={classes.input} fullWidth variant="filled">
            <InputLabel htmlFor="name">Nom</InputLabel>
            <FilledInput
              name="name"
              id="name"
              inputRef={register({ required: true })}
              required
            />
          </FormControl>

          <ClientsInput campaign={campaign} />

          <div className={classes.spacer} />

          <CappingInput campaign={campaign} />

          {!props.campaign && (
            <Button
              className={classes.button}
              disabled={formDisabled}
              variant="contained"
              type="submit"
              onClick={(): void => setWizardMode(true)}
              color="secondary"
            >
              Continuer (Ensemble)
            </Button>
          )}

          <Button
            disabled={formDisabled}
            className={classes.button}
            type="submit"
            variant="outlined"
            color="secondary"
          >
            Terminer
          </Button>
        </form>
      </FormContext>
    </div>
  );
}
