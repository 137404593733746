import React from 'react';

import HeaderCampaign from '../campaigns/header-campaign';
import ListCampain from '../campaigns/list-campain';
import { useAuth } from '../shared/hooks/useAuth';

function Manager(): JSX.Element {
  const [shouldRefreshAuth, isLoggedIn] = useAuth();

  if (shouldRefreshAuth) {
    return <p>Reconnexion en cours...</p>;
  }

  if (!isLoggedIn) {
    return <p>Vous êtes déconnecté.</p>;
  }

  return (
    <>
      <HeaderCampaign />
      <ListCampain />
    </>
  );
}

export default Manager;
