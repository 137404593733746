import { createStyles, makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CampaignWithoutId, CappingTypeEnum } from '../queries';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: '100%',
    },
  })
);

interface Props {
  name: string;
  disabled?: boolean;
}

export default function CappingTypeSelect(props: Props): JSX.Element {
  const classes = useStyles();

  const { name, disabled } = props;

  const { register, unregister, setValue, watch } = useFormContext<
    CampaignWithoutId
  >();

  const inputValue = watch(name) as CappingTypeEnum;

  useEffect(() => {
    register({ name }, { required: false });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  async function handleChange(
    evt: React.ChangeEvent<{ value: unknown }>
  ): Promise<void> {
    const value = evt.target.value as CappingTypeEnum;
    await setValue(name, value);
  }

  return (
    <FormControl className={classes.input} variant="filled" disabled={disabled}>
      <InputLabel>Capping</InputLabel>
      <Select id={name} name={name} onChange={handleChange} value={inputValue}>
        <MenuItem value={CappingTypeEnum.None}>Aucun</MenuItem>
        <MenuItem value={CappingTypeEnum.CPC}>CPC</MenuItem>
        <MenuItem value={CappingTypeEnum.CPM}>CPM</MenuItem>
      </Select>
    </FormControl>
  );
}
