import { Action, action, createContextStore } from 'easy-peasy';
import { stringify } from 'query-string';
import { XOR } from 'ts-xor';

import { isProdPlatform } from '../environment';

export enum ResourceTypeEnum {
  Campaign = 'Campaign',
  AdSet = 'AdSet',
  Ad = 'Ad',
}

export interface EditingStatus {
  type: ResourceTypeEnum;
  id?: string;
  parentId?: string;
  dirty?: boolean;
  reOpen?: boolean;
  editAfterCreation?: boolean;
}

export type StartEditingPayload = Pick<EditingStatus, 'type'> &
  XOR<Pick<EditingStatus, 'id'>, Pick<EditingStatus, 'parentId'>>;

export interface EditingStoreModel {
  editingStatus?: EditingStatus;
  loading: boolean;

  startEditing: Action<EditingStoreModel, StartEditingPayload>;
  stopEditing: Action<EditingStoreModel>;
  setDirty: Action<EditingStoreModel>;
  setReOpen: Action<EditingStoreModel, boolean>;
  setEditAfterCreation: Action<EditingStoreModel, boolean>;
  setLoading: Action<EditingStoreModel, boolean>;
}

function defineQueryParams(state?: EditingStatus): void {
  let url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  if (state) {
    const currentState = {
      type: state.type,
      id: state.id,
      parentId: state.parentId,
    };
    url += `?${stringify(currentState)}`;
  }

  window.history.pushState({ path: url }, '', url);
}

export const editingModel: EditingStoreModel = {
  // === STATE ===
  editingStatus: undefined,
  loading: false,
  // === ACTIONS ===
  startEditing: action((state, payload) => {
    state.editingStatus = payload;
    state.editingStatus.dirty = false;
    defineQueryParams(state.editingStatus);
  }),

  stopEditing: action((state) => {
    state.editingStatus = undefined;
    defineQueryParams(state.editingStatus);
  }),

  setDirty: action((state) => {
    if (state.editingStatus && !state.editingStatus.dirty) {
      state.editingStatus.dirty = true;
    }
  }),

  setReOpen: action((state, flag) => {
    if (state.editingStatus) {
      state.editingStatus.reOpen = flag;
    }
  }),

  setEditAfterCreation: action((state, flag) => {
    if (state.editingStatus) {
      state.editingStatus.editAfterCreation = flag;
    }
  }),
  setLoading: action((state, flag) => {
    state.loading = flag;
  }),
};

export const editingStore = createContextStore(editingModel, {
  name: 'EditingStore',
  devTools: !isProdPlatform,
});

export const {
  Provider: EditingStoreProvider,
  useStoreActions: useEditingActions,
  useStoreState: useEditingState,
} = editingStore;
