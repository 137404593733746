import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ALL_BANKS, Bank } from '../../shared/utils/banks';
import { AdSet } from '../queries';

type MUIAutocompleteChangeEvent = ChangeEvent<{}>;

interface Props {
  name: string;
}

function BankInput(props: Props): JSX.Element {
  const { name } = props;
  const {
    register,
    unregister,
    setValue,
    triggerValidation,
    formState,
    watch,
  } = useFormContext<AdSet>();

  const banks = ((watch(name) || []) as string[]).map((c) =>
    ALL_BANKS.find(({ value }) => c === value)
  ) as Bank[];

  useEffect(() => {
    register({ name });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  async function handleChange(
    _: MUIAutocompleteChangeEvent,
    value: Bank[]
  ): Promise<void> {
    await setValue(
      name,
      value.map((val) => val.value)
    );

    if (formState.isSubmitted) {
      await triggerValidation(name);
    }
  }

  return (
    <Autocomplete
      multiple
      options={ALL_BANKS}
      getOptionLabel={(bank: Bank): string => `${bank.label}`}
      value={banks}
      filterSelectedOptions
      onChange={handleChange}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          variant="outlined"
          label={`Banques à ${
            /added/.test(name) ? 'ajouter' : 'retirer'
          } du ciblage`}
          margin="normal"
          fullWidth
        />
      )}
    />
  );
}
export default BankInput;
