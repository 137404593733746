import {
  Avatar,
  Chip,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import GradeTwoToneIcon from '@material-ui/icons/GradeTwoTone';
import React from 'react';

import { PriorityEnum } from '../../ad-sets/queries';
import { SpotColor } from '../../ads/ad-item';
import { SpotEnum } from '../../ads/queries';
import { CalendarEvent } from '../inventory';

interface Props {
  event: CalendarEvent;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customRbcEvent: {
      display: 'flex',
      flexWrap: 'wrap',
      color: theme.palette.primary.main,
      zIndex: 0,
      position: 'relative',
    },
    titleWrapper: {
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(0.4),
      height: '1.218rem',
    },
    customBackground: {
      '&::before': {
        content: "' '",
        position: 'absolute',
        left: '0',
        top: '30%',
        width: '100%',
        height: '40%',
        background: 'inherit',
        zIndex: -1,
        opacity: 0.8,
      },
    },
    master: {
      width: theme.spacing(1.8),
      height: theme.spacing(1.8),
    },
    campaignWrapper: {
      textTransform: 'capitalize',
    },
    priorityWrapper: {
      textTransform: 'capitalize',
      height: '1.218rem',
    },
    avatar: {
      marginLeft: '-1px !important',
    },
  })
);

export function CustomEvent(props: Props): JSX.Element | null {
  const { event } = props;
  const { title, priority, campaign, resourceId } = event;
  const classes = useStyles();

  return (
    <span className={classes.customRbcEvent}>
      <span
        className={classes.customBackground}
        style={{ backgroundColor: SpotColor[resourceId as SpotEnum] }}
      />
      <Chip
        size="small"
        className={classes.titleWrapper}
        avatar={
          <Avatar className={classes.avatar}>
            {priority === PriorityEnum.Master ? (
              <GradeTwoToneIcon color="primary" className={classes.master} />
            ) : (
              priority.charAt(0)
            )}
          </Avatar>
        }
        label={`${campaign} - ${title ? title.toString() : resourceId}`}
      />
    </span>
  );
}
