import gql from 'graphql-tag';

export interface Ad<T extends AdContentTypeEnum> {
  id: string;
  adSetId: string;
  name: string;
  active: boolean;
  spot: SpotEnum;
  content?: { type: T } & AdContentByType[T];
}

export type AdDraft = Partial<Omit<Ad<AdContentTypeEnum>, 'content'>>;

export enum SpotEnum {
  VerticalBannerLaRuche = 'VerticalBannerLaRuche',
  BannerLaRuche = 'BannerLaRuche',
  VerticalBannerWizbii = 'VerticalBannerWizbii',
  BannerWizbii = 'BannerWizbii',
  JobNativeAdWizbii = 'JobNativeAdWizbii',
  JobNativeSidebarAdWizbii = 'JobNativeSidebarAdWizbii',
  MenuEntryWizbii = 'MenuEntryWizbii',
  LandingWizbii = 'LandingWizbii',
  HelpNativeAdFibii = 'HelpNativeAdFibii',
  TopBarAdWizbii = 'TopBarAdWizbii',
}

export enum AdContentTypeEnum {
  Image = 'Image',
  JobNativeAd = 'JobNativeAd',
  JobNativeSidebarAd = 'JobNativeSidebarAd',
  MenuEntry = 'MenuEntry',
  HelpNativeAd = 'HelpNativeAd',
  TopBarAd = 'TopBarAd',
  Article = 'Article',
}

export const LabelBySpotEnum: Record<SpotEnum | string, string> = {
  [SpotEnum.VerticalBannerLaRuche]: 'La Ruche - Vertical Banner',
  [SpotEnum.BannerLaRuche]: 'La Ruche - Banner',
  [SpotEnum.VerticalBannerWizbii]: 'Wizbii - Vertical Banner',
  [SpotEnum.BannerWizbii]: 'Wizbii - Banner',
  [SpotEnum.JobNativeAdWizbii]: 'Wizbii - Native Ad (Job)',
  [SpotEnum.JobNativeSidebarAdWizbii]: 'Wizbii - Native Sidebar Ad (Job)',
  [SpotEnum.MenuEntryWizbii]: 'Wizbii - Menu Entry',
  [SpotEnum.LandingWizbii]: 'Wizbii - Landing Page',
  [SpotEnum.HelpNativeAdFibii]: 'Fibii - Native Ad (Help)',
  [SpotEnum.TopBarAdWizbii]: 'Wizbii - Top Bar Ad',
  all: 'Tous',
};
export const ShortLabelBySpotEnum: Record<SpotEnum | string, string> = {
  [SpotEnum.VerticalBannerLaRuche]: 'Vertical Banner',
  [SpotEnum.BannerLaRuche]: 'Banner',
  [SpotEnum.VerticalBannerWizbii]: 'Vertical Banner',
  [SpotEnum.BannerWizbii]: 'Banner',
  [SpotEnum.JobNativeAdWizbii]: 'Native Ad',
  [SpotEnum.JobNativeSidebarAdWizbii]: 'Native Sidebar Ad',
  [SpotEnum.MenuEntryWizbii]: 'Menu Entry',
  [SpotEnum.LandingWizbii]: 'Landing Page',
  [SpotEnum.HelpNativeAdFibii]: 'Native Ad',
  [SpotEnum.TopBarAdWizbii]: 'Top Bar Ad',
};

export const LabelByAdContentTypeEnum: Record<AdContentTypeEnum, string> = {
  [AdContentTypeEnum.Image]: 'Image',
  [AdContentTypeEnum.JobNativeAd]: 'Job Native Ad',
  [AdContentTypeEnum.JobNativeSidebarAd]: 'Job Native Sidebar Ad',
  [AdContentTypeEnum.MenuEntry]: 'Menu Entry',
  [AdContentTypeEnum.HelpNativeAd]: 'Help Native Ad',
  [AdContentTypeEnum.TopBarAd]: 'Top Bar Ad',
  [AdContentTypeEnum.Article]: 'Article',
};

export interface AdContentByType {
  [AdContentTypeEnum.Image]: ImageType;
  [AdContentTypeEnum.JobNativeAd]: JobNativeAdType;
  [AdContentTypeEnum.JobNativeSidebarAd]: JobNativeSidebarAdType;
  [AdContentTypeEnum.MenuEntry]: MenyEntryType;
  [AdContentTypeEnum.HelpNativeAd]: HelpNativeAdType;
  [AdContentTypeEnum.TopBarAd]: TopBarAdType;
  [AdContentTypeEnum.Article]: ArticleType;
}

export enum SiteEnum {
  Wizbii = 'Wizbii',
  Fibii = 'Fibii',
  LaRuche = 'La Ruche',
}

export const SpotBySite: Record<SiteEnum, SpotEnum[]> = {
  [SiteEnum.Wizbii]: [
    SpotEnum.JobNativeSidebarAdWizbii,
    SpotEnum.JobNativeAdWizbii,
    SpotEnum.MenuEntryWizbii,
    SpotEnum.BannerWizbii,
    SpotEnum.VerticalBannerWizbii,
    SpotEnum.LandingWizbii,
    SpotEnum.TopBarAdWizbii,
  ],
  [SiteEnum.Fibii]: [SpotEnum.HelpNativeAdFibii],
  [SiteEnum.LaRuche]: [SpotEnum.BannerLaRuche, SpotEnum.VerticalBannerLaRuche],
};

export const SiteBySpot: Record<SpotEnum, SiteEnum> = {
  [SpotEnum.BannerLaRuche]: SiteEnum.LaRuche,
  [SpotEnum.VerticalBannerWizbii]: SiteEnum.Wizbii,
  [SpotEnum.VerticalBannerLaRuche]: SiteEnum.LaRuche,
  [SpotEnum.HelpNativeAdFibii]: SiteEnum.Fibii,
  [SpotEnum.JobNativeSidebarAdWizbii]: SiteEnum.Wizbii,
  [SpotEnum.JobNativeAdWizbii]: SiteEnum.Wizbii,
  [SpotEnum.BannerWizbii]: SiteEnum.Wizbii,
  [SpotEnum.MenuEntryWizbii]: SiteEnum.Wizbii,
  [SpotEnum.LandingWizbii]: SiteEnum.Wizbii,
  [SpotEnum.TopBarAdWizbii]: SiteEnum.Wizbii,
};

export interface ImageType {
  description: string;
  link: string;
  mustAddLoginToken: boolean;
  blank: boolean;
  img: string;
  width: number;
}

export interface JobNativeAdType {
  title: string;
  subtitle: string;
  link: string;
  mustAddLoginToken: boolean;
  blank: boolean;
  img: string;
  companyName: string;
  buttonText: string;
}

export interface JobNativeSidebarAdType {
  companyName: string;
  title: string;
  description: string;
  link: string;
  blank: boolean;
  mustAddLoginToken: boolean;
}

export interface HelpNativeAdType {
  title: string;
  subtitle: string;
  highlightedSubtitle: string;
  link: string;
  mustAddLoginToken: boolean;
  blank: boolean;
  partnerSite: string;
  buttonText: string;
  legals: string;
}

export interface TopBarAdType {
  textHTML: string;
  mustAddLoginToken: boolean;
  link: string;
  blank: boolean;
  buttonText: string;
}

export interface MenyEntryType {
  title: string;
  link: string;
  mustAddLoginToken: boolean;
  blank: boolean;
  img: string;
}

export interface ArticleType {
  title: string;
  logoUrl: string;
  bannerUrl: string;
  introduction: string;
  body: string;
  legals: string;
  buttonText: string;
  link: string;
  mustAddLoginToken: boolean;
  blank: boolean;
}

export type AdWithoutIds = Omit<Ad<AdContentTypeEnum>, 'id' | 'adSetId'>;
export type AdInput = Omit<Ad<AdContentTypeEnum>, 'id'>;

export interface GetAdResult {
  ad: Ad<AdContentTypeEnum>;
}

export const GET_AD = gql`
  query GetAd($id: String!) {
    ad(id: $id) @rest(type: "Ad", path: "/ads/{args.id}") {
      id
      adSetId
      name
      active
      spot
      content
    }
  }
`;

export interface GetAdsByAdSetIdResult {
  ads: Ad<AdContentTypeEnum>[];
}

export const AD_FRAGMENT = gql`
  fragment AdFragment on Ad {
    id
    adSetId
    placement
    name
    active
    content
    spot
  }
`;

export const GET_ADS_BY_ADSET_ID = gql`
  query GetAdsByAdsetId($adSetId: String!) {
    ads(adSetId: $adSetId)
      @rest(type: "[Ad]", path: "/ads?adSetIds={args.adSetId}") {
      ...AdFragment
    }
  }
  ${AD_FRAGMENT}
`;

export interface CreateAdParams {
  input: AdInput;
}
export interface CreateAdDraftParams {
  input: AdDraft;
}

export interface SaveAdParams {
  id: string;
  input: AdInput;
}

export interface DeleteAdParams {
  id: string;
}

export interface CreateAdResult {
  ad: {
    id: string;
  };
}

export const CREATE_AD = gql`
  mutation CreateAd($input: Ad!) {
    ad(input: $input) @rest(type: "Ad", path: "/ads", method: "POST") {
      id
    }
  }
`;

export const SAVE_AD = gql`
  mutation SaveAd($id: String!, $input: AdInput!) {
    ad(id: $id, input: $input)
      @rest(type: "Ad", path: "/ads/{args.id}", method: "PUT") {
      id
    }
  }
`;

export interface EditAdParams {
  id: string;
  input: Partial<Ad<AdContentTypeEnum>>;
}

export const EDIT_AD = gql`
  mutation EditAdSet($id: String!, $input: AdInput!) {
    ad(id: $id, input: $input)
      @rest(type: "Ad", path: "/ads/{args.id}", method: "PATCH") {
      id
    }
  }
`;

export const DELETE_AD = gql`
  mutation DeleteAd($id: String!) {
    ad(id: $id) @rest(type: "Ad", path: "/ads/{args.id}", method: "DELETE") {
      id
    }
  }
`;
