import { useMutation } from '@apollo/react-hooks';
import { FormGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';

import ActiveInput from '../../shared/inputs/active-input';
import {
  Ad,
  AdContentTypeEnum,
  EDIT_AD,
  EditAdParams,
  GET_ADS_BY_ADSET_ID,
} from '../queries';

interface Props {
  ad: Ad<AdContentTypeEnum>;
  active: boolean;
}

export default function AdActiveForm(props: Props): JSX.Element {
  const { ad, active } = props;

  const formMethods = useForm<Ad<AdContentTypeEnum>>({ defaultValues: ad });
  const { setValue, getValues } = formMethods;

  const [disabled, setDisabled] = useState(false);

  const [editAdSet] = useMutation<void, EditAdParams>(EDIT_AD, {
    refetchQueries: [
      {
        query: GET_ADS_BY_ADSET_ID,
        variables: { campaignId: ad.adSetId },
      },
    ],
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setValue('active', ad.active);
  }, [ad.active, setValue]);

  async function changeValue(): Promise<void> {
    setDisabled(true);

    const { active } = getValues();

    await editAdSet({
      variables: {
        id: ad.id,
        input: {
          active,
        },
      },
    });

    setDisabled(false);
  }

  return (
    <FormContext {...formMethods}>
      <form>
        <FormGroup>
          <ActiveInput
            color={'primary'}
            handleClick={changeValue}
            small={true}
            disabled={!active && disabled}
            name="active"
          />
        </FormGroup>
      </form>
    </FormContext>
  );
}
