import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { RestLink } from 'apollo-link-rest';
import { cloneDeepWith } from 'lodash-es';

import { AdSet, Criteria } from './ad-sets/queries';
import { Ad, AdContentTypeEnum } from './ads/queries';
import { mediaApiBaseUrl } from './environment';
import { isExpired, refreshAuth } from './shared/utils/auth';
import { transformDates } from './shared/utils/utils';
import { store } from './store';

/**
 * Add `Authorization` header to every Apollo Client request when the user is logged in.
 * https://www.apollographql.com/docs/link/links/context/
 */
const authLink = setContext(() => {
  const { isLoggedIn, decodedToken } = store.getState();

  if (isLoggedIn) {
    // If token has expired, attempt to refresh it first
    return (isExpired(decodedToken) ? refreshAuth() : Promise.resolve()).then(
      () => {
        const { token } = store.getState();
        if (!token) {
          throw new Error('No token after refresh');
        }

        return { headers: { Authorization: `Bearer ${token}` } };
      }
    );
  }
});

/**
 * Query the Media API, a REST API, with GraphQL.
 * https://www.apollographql.com/docs/link/links/rest/
 */
const restLink = new RestLink({
  uri: `${mediaApiBaseUrl}/v2`,

  // Transform strings to JS dates in response payloads
  responseTransformer: async (response): Promise<object> => {
    if (!response.json) {
      return response;
    }

    const data = await response.json();
    return cloneDeepWith(data, transformDates);
  },

  // Path types of nested objects in response payloads
  typePatcher: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AdSet: (obj: Partial<AdSet>, _, patchDeeper): any => ({
      ...obj,
      ...(obj.criteria // when deleting, `obj` equals `{ id: null }`
        ? { criteria: patchDeeper(obj.criteria, 'Criteria', patchDeeper) }
        : {}),
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Criteria: (obj: Criteria, _, patchDeeper): any => ({
      ...obj,
      localisation: patchDeeper(
        obj.localisation,
        'LocalisationCriteria',
        patchDeeper
      ),
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Ad: (obj: Partial<Ad<AdContentTypeEnum>>, _, patchDeeper): any => ({
      ...obj,
      ...(obj.content // when deleting, `obj` equals `{ id: null }`
        ? {
            content: patchDeeper(obj.content, 'AdContentByFormat', patchDeeper),
          }
        : {}),
    }),
  },
});

/**
 * Apollo Client for communicating with the Media API and caching fetched entities (Campaigns, Ad Sets, Ads, etc.)
 * https://www.apollographql.com/docs/react/
 * https://www.apollographql.com/docs/react/api/react-hooks/
 */
export const apolloClient = new ApolloClient({
  link: from([authLink, restLink]),
  cache: new InMemoryCache(),
  typeDefs: [], // https://github.com/apollographql/apollo-client-devtools/issues/228
});
