import { Button, withStyles } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

import { previewUrlPerSpot } from '../../../shared/utils/redir';
import { Ad, AdContentTypeEnum, SpotEnum } from '../../queries';

interface Props {
  ad: Ad<AdContentTypeEnum>;
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: theme.spacing(1.8),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    link: {
      textDecoration: 'unset',
    },
  })
);

function PreviewButton(props: Props): JSX.Element {
  const { ad } = props;

  const preview = previewUrlPerSpot[ad.spot];

  const url = `${preview.path}?${
    ad.spot === SpotEnum.LandingWizbii ? 'messageId' : 'previewId'
  }=${ad.id}`;

  const classes = useStyles();

  return (
    <LightTooltip
      placement="top"
      title={
        preview.infos ? (
          <span className={classes.tooltip}>{preview.infos}</span>
        ) : (
          ''
        )
      }
    >
      <a
        href={url}
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer"
      >
        <Button type="button" variant="outlined" color="primary">
          Voir en ligne
        </Button>
      </a>
    </LightTooltip>
  );
}

export default PreviewButton;
