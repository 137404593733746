import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  usePreviewActions,
  usePreviewState,
} from '../../../manager/preview-store';
import { uploadFile } from '../../../shared/utils/file';
import { MiniThumb } from './mini-thumb';

interface Props {
  name: string;
  label: string;
  required?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '56px',
      display: 'flex',
      flex: '1 1 0',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    thumb: {
      marginLeft: 'auto',
    },
    fileInput: {
      display: 'none',
    },
    deleteBtn: {
      marginLeft: theme.spacing(1),
      opacity: 0.8,
    },
  })
);

function MediaInput(props: Props): JSX.Element {
  const { name, label, required } = props;

  const classes = useStyles();
  const fileInput = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const showPreview = usePreviewActions((actions) => actions.showPreview);
  const clearPreview = usePreviewActions((actions) => actions.clearPreview);
  const currentPreview = usePreviewState((state) => state.currentPreview);

  let file = null;

  const {
    setValue,
    formState,
    triggerValidation,
    register,
    unregister,
    watch,
  } = useFormContext();

  const inputValue = watch(name);

  useEffect(() => {
    register({ name, required });

    return (): void => unregister([name]);
  }, [register, unregister, name, required]);

  async function handleChange(): Promise<void> {
    if (
      !fileInput.current ||
      !fileInput.current.files ||
      fileInput.current.files.length === 0
    ) {
      return;
    }

    setLoading(true);
    file = fileInput.current.files[0];

    const publicUrl = await uploadFile(file);
    await setValue(name, publicUrl);

    if (formState.isSubmitted) {
      await triggerValidation(name);
    }

    setLoading(false);

    showPreview(publicUrl);
  }

  async function deleteMedia(): Promise<void> {
    if (currentPreview === inputValue) {
      clearPreview();
    }
    await setValue(name, '');
  }

  return (
    <FormControl className={classes.wrapper} fullWidth variant="filled">
      <input
        className={classes.fileInput}
        name={name}
        onChange={handleChange}
        multiple={false}
        type="file"
        accept="image/*"
        id={`${name}-upload-input`}
        ref={fileInput}
      />

      <label htmlFor={`${name}-upload-input`}>
        <Button
          disabled={loading}
          variant="outlined"
          color="secondary"
          component="span"
        >
          {(inputValue ? 'Remplacer ' : 'Ajouter ') + label}
        </Button>
      </label>

      <IconButton
        aria-label="delete"
        onClick={deleteMedia}
        className={classes.deleteBtn}
      >
        <Delete color="secondary" fontSize="small" />
      </IconButton>

      {
        <div className={classes.thumb}>
          {loading && <CircularProgress size={32} color="secondary" />}

          {!loading && inputValue && <MiniThumb imageUrl={inputValue} />}
        </div>
      }
    </FormControl>
  );
}
export default MediaInput;
