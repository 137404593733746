import 'date-fns'; // eslint-disable-line
import React, { ChangeEvent, useEffect } from 'react';
import {
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { AdSet, PriorityEnum } from '../queries';

export const LabelByPriority = {
  [PriorityEnum.Master]: 'Master 🦸 (affiché 100% du temps)',
  [PriorityEnum.High]: 'Haute',
  [PriorityEnum.Medium]: 'Moyenne',
  [PriorityEnum.Low]: 'Faible',
  [PriorityEnum.Default]: 'Par défaut (affiché si rien d’autre)',
};

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      marginLeft: 0,
      marginRight: 0,
    },
  })
);

type MUISelectChangeEvent = ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

interface InputProps {
  name: string;
}

function PriorityInput({ name }: InputProps): JSX.Element {
  const classes = useStyles();

  const {
    register,
    unregister,
    setValue,
    triggerValidation,
    formState,
    watch,
  } = useFormContext<Partial<AdSet>>();

  const inputValue = watch(name) as PriorityEnum;

  useEffect(() => {
    register({ name }, { required: true });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  async function handleChange(evt: MUISelectChangeEvent): Promise<void> {
    const value = evt.target.value as PriorityEnum;
    await setValue(name, value);

    if (formState.isSubmitted) {
      await triggerValidation(name);
    }
  }

  return (
    <FormControl className={classes.input} fullWidth variant="outlined">
      <Select
        labelId={name}
        id={name}
        name={name}
        onChange={handleChange}
        value={inputValue}
      >
        {Object.keys(PriorityEnum).map((key, index) => (
          <MenuItem key={index} value={key}>
            {LabelByPriority[key as PriorityEnum]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default PriorityInput;
