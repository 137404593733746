import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { SiteEnum } from '../../queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: theme.spacing(18),
      padding: theme.spacing(1, 3),
      border: '1px solid #e5e5e5',
      cursor: 'pointer',
      margin: theme.spacing(0, 2, 2, 0),
      color: theme.palette.primary.main,
      textAlign: 'center',
      transition: theme.transitions.create('background-color'),
    },
    selected: {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      borderColor: '#fff',
    },
  })
);

interface Props {
  site: SiteEnum;
  selected?: boolean;
  handleClick?: (site: SiteEnum) => void;
}

export function SiteCard(props: Props): JSX.Element {
  const { site, selected, handleClick } = props;

  const classes = useStyles();

  function activate(): void {
    if (handleClick) {
      handleClick(site);
    }
  }

  const activeClasses = [classes.card, selected && classes.selected].join(' ');

  return (
    <div className={activeClasses} onClick={activate}>
      {site}
    </div>
  );
}
