import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.secondary.main,
      flexBasis: '45%',
      flexShrink: 0,
      marginRight: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    secondaryHeading: {
      fontSize: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    icon: {
      marginRight: theme.spacing(1.5),
      display: 'flex',
      opacity: '0.5',
      transform: 'scale(0.9)',
    },
  })
);

const ExpansionPanelSummary = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .02)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    paddingLeft: theme.spacing(2),
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiExpansionPanelSummary);

interface Props {
  label: string;
  summary?: JSX.Element;
  icon: JSX.Element;
}

function PanelHeader(props: Props): JSX.Element {
  const { label, summary, icon } = props;

  const classes = useStyles();

  return (
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
    >
      <div className={classes.heading}>
        <div className={classes.icon}>{icon}</div>
        <div>{label}</div>
      </div>
      <div className={classes.secondaryHeading}>{summary}</div>
    </ExpansionPanelSummary>
  );
}

export default PanelHeader;
