import { useMutation } from '@apollo/react-hooks';
import React from 'react';

import { ResourceTypeEnum, useEditingActions } from '../manager/editing-store';
import AdSetForm from './ad-set-form';
import {
  AdSetWithoutIds,
  CREATE_ADSET,
  CreateAdSetParams,
  CreateAdSetResult,
  GET_ADSETS_BY_CAMPAIGN_ID,
} from './queries';

interface Props {
  parentId: string;
}

function CreateAdSet(props: Props): JSX.Element | null {
  const { parentId } = props;
  const stopEditing = useEditingActions((actions) => actions.stopEditing);
  const startEditing = useEditingActions((actions) => actions.startEditing);
  const setLoading = useEditingActions((actions) => actions.setLoading);

  const [createAdSet] = useMutation<CreateAdSetResult, CreateAdSetParams>(
    CREATE_ADSET,
    {
      refetchQueries: [
        {
          query: GET_ADSETS_BY_CAMPAIGN_ID,
          variables: { campaignId: parentId },
        },
      ],
    }
  );

  async function handleSubmit(
    input: AdSetWithoutIds,
    wizardMode: boolean
  ): Promise<void> {
    const { startDate, endDate, criteria } = input;

    setLoading(true);

    await createAdSet({
      variables: {
        input: {
          campaignId: parentId,
          ...input,
          startDate: startDate.toUTCString(),
          endDate: endDate.toUTCString(),
          criteria: {
            ...criteria,
          },
        },
      },
    })
      .then((response) => {
        if (response.data && wizardMode) {
          setTimeout(() => {
            startEditing({
              type: ResourceTypeEnum.Ad,
              parentId: response.data ? response.data.adSet.id : undefined,
            });
          }, 250);
        }
        stopEditing();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return <AdSetForm campaignId={parentId} onSubmit={handleSubmit} />;
}

export default CreateAdSet;
