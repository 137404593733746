import { useMutation } from '@apollo/react-hooks';
import React from 'react';

import { ResourceTypeEnum, useEditingActions } from '../manager/editing-store';
import { useFilterState } from '../manager/filter-store';
import CampaignForm from './campaign-form';
import {
  buildGetCampaignQuery,
  CampaignWithoutId,
  CREATE_CAMPAIGN,
  CreateCampaignParams,
  CreateCampaignResult,
  GET_CAMPAIGNS,
} from './queries';

function CreateCampaign(): JSX.Element | null {
  const stopEditing = useEditingActions((actions) => actions.stopEditing);
  const startEditing = useEditingActions((actions) => actions.startEditing);

  const setLoading = useEditingActions((actions) => actions.setLoading);

  const filterSettings = useFilterState((state) => state.currentSettings);

  const [createCampaign] = useMutation<
    CreateCampaignResult,
    CreateCampaignParams
  >(CREATE_CAMPAIGN, {
    refetchQueries: [
      {
        query: GET_CAMPAIGNS,
        variables: buildGetCampaignQuery(filterSettings),
      },
    ],
  });

  async function handleSubmit(
    input: CampaignWithoutId,
    wizardMode: boolean
  ): Promise<void> {
    setLoading(true);

    await createCampaign({
      variables: { input },
    })
      .then((response) => {
        if (response.data && wizardMode) {
          setTimeout(() => {
            startEditing({
              type: ResourceTypeEnum.AdSet,
              parentId: response.data ? response.data.campaign.id : undefined,
            });
          }, 250);
        }

        stopEditing();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return <CampaignForm onSubmit={handleSubmit} />;
}

export default CreateCampaign;
