import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';

import TextInput from '../inputs/text-input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
  })
);

function HelpNativeAdGroup(): JSX.Element {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="title">Titre</InputLabel>

        <TextInput name="content.title" required={true} />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="highlightedSubtitle">
          Sous-titre surligné (optionnel)
        </InputLabel>

        <TextInput name="content.highlightedSubtitle" />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="subtitle">Sous-titre</InputLabel>

        <TextInput name="content.subtitle" required={true} />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="legals">
          Conditions d&apos;utilisation (optionnel)
        </InputLabel>

        <TextInput name="content.legals" />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="partnerSite">Site partenaire</InputLabel>
        <TextInput name="content.partnerSite" required={true} />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="buttonText">Texte du bouton</InputLabel>
        <TextInput name="content.buttonText" required={true} />
      </FormControl>
    </div>
  );
}

export default HelpNativeAdGroup;
