import { FilledInput } from '@material-ui/core';
import React, { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Ad, AdContentTypeEnum } from '../../queries';

interface Props {
  name: string;
  required?: boolean;
  multiline?: boolean;
}

function TextInput(props: Props): JSX.Element {
  const { name, required, multiline } = props;

  const {
    register,
    unregister,
    setValue,
    triggerValidation,
    formState,
    watch,
  } = useFormContext<Ad<AdContentTypeEnum>>();

  const inputValue = watch(name);

  useEffect(() => {
    register({ name }, { required: !!required });
    return (): void => unregister([name]);
  }, [register, unregister, name, required]);

  async function handleChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
    await setValue(name, e.target.value);

    if (formState.isSubmitted) {
      await triggerValidation(name);
    }
  }

  return (
    <FilledInput
      name={name}
      multiline={multiline}
      rows={multiline ? 3 : undefined}
      onChange={handleChange}
      value={inputValue ? inputValue : ''}
    />
  );
}
export default TextInput;
