import 'date-fns'; // eslint-disable-line
import React from 'react';
import { FormControl } from '@material-ui/core';
import { Client } from '../../campaigns/queries';
import CheckboxInput from '../../shared/inputs/checkbox-input';

interface Props {
  name: string;
  campaignClient: Client | null;
}

function LoyaltyCriteria(props: Props): JSX.Element {
  const { name, campaignClient } = props;

  const none = `${name}.none`;
  const client = `${name}.client`;
  const other = `${name}.other`;

  const hasExternalPackageId = !!(
    campaignClient && !!campaignClient.externalPackageId
  );

  return (
    <FormControl component="fieldset">
      <CheckboxInput disabled={false} name={none} label="Non-loyalty" />

      <CheckboxInput
        name={client}
        label="Loyalty du client"
        disabled={!hasExternalPackageId}
      />

      <CheckboxInput disabled={false} name={other} label="Loyalty non-client" />
    </FormControl>
  );
}

export default LoyaltyCriteria;
