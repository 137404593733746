import { useQuery } from '@apollo/react-hooks';
import {
  Button,
  createStyles,
  FilledInput,
  FormControl,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { addWeeks } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';

import { GET_CAMPAIGN, GetCampaignResult } from '../campaigns/queries';
import { useEditingActions, useEditingState } from '../manager/editing-store';
import ActiveInput from '../shared/inputs/active-input';
import { LangEnum } from '../shared/utils/lang';
import { preventEnterSubmit } from '../shared/utils/utils';
import AdSetSettings from './ad-set-settings';
import {
  AdSet,
  AdSetWithoutIds,
  LocalisationZoneEnum,
  PriorityEnum,
} from './queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(2),
    },
    wrapper: {
      padding: theme.spacing(5),
      overflowY: 'scroll',
    },
    box: {
      marginBottom: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    button: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
    settings: {
      marginTop: theme.spacing(2),
    },
    activeSwitch: {
      marginTop: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium,
    },
  })
);

const defaultValues = {
  name: '',
  startDate: new Date(new Date().setHours(0, 1, 0)),
  endDate: addWeeks(new Date().setHours(23, 59, 59), 1),
  priority: PriorityEnum.Medium,
  active: true,
  criteria: {
    loyaltyPackageId: { none: true, client: false, other: false },
    age: undefined,
    localisation: {
      zone: LocalisationZoneEnum.France,
      addedDepartments: [],
      substractedDepartments: [],
      countries: [],
    },
    banks: {
      substractedBanks: [],
    },
    drivingLicense: false,
    student: false,
    studentLoan: false,
    userLanguages: [LangEnum.fr],
  },
};

interface Props {
  adSet?: AdSet;
  campaignId: string;
  onSubmit(values: AdSetWithoutIds, wizardMode: boolean): Promise<void>;
}

function AdSetForm(props: Props): JSX.Element {
  const { adSet, onSubmit, campaignId } = props;
  const classes = useStyles();
  const [wizardMode, setWizardMode] = useState(false);

  const setDirty = useEditingActions((actions) => actions.setDirty);
  const formDisabled = useEditingState((state) => state.loading);

  const { data: campaignData } = useQuery<GetCampaignResult>(GET_CAMPAIGN, {
    variables: { id: campaignId },
  });

  const campaignClient = campaignData ? campaignData.campaign.client : null;

  if (
    campaignData &&
    campaignData.campaign.client.departments &&
    campaignData.campaign.client.departments.length
  ) {
    defaultValues.criteria.localisation.addedDepartments = campaignData.campaign
      .client.departments as never[];
  }

  const formMethods = useForm<AdSet>({ defaultValues: adSet || defaultValues });
  const { watch, register, handleSubmit, formState } = formMethods;

  useEffect(() => {
    if (formState.dirty) {
      setDirty();
    }
  }, [formState, setDirty]);

  const localisationZone = watch(
    'criteria.localisation.zone'
  ) as LocalisationZoneEnum;

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant={'h5'} className={classes.input}>
          Ensemble de pubs
        </Typography>
        <Typography color={'textSecondary'} className={classes.box}>
          Définir les paramètres de l’ensemble de publicités
        </Typography>
      </div>

      <FormContext {...formMethods}>
        <form
          className={classes.box}
          onSubmit={handleSubmit(async (input) => onSubmit(input, wizardMode))}
          noValidate
          onKeyPress={preventEnterSubmit}
        >
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="name">Nom</InputLabel>
            <FilledInput name="name" id="name" inputRef={register} required />
          </FormControl>

          <FormControl className={classes.activeSwitch}>
            <ActiveInput
              name="active"
              color={'primary'}
              label={['Actif', 'Inactif']}
            />
          </FormControl>

          <div className={classes.settings}>
            <AdSetSettings
              localisationZone={localisationZone}
              campaignClient={campaignClient}
            />
          </div>

          {!adSet && (
            <Button
              className={classes.button}
              variant="contained"
              disabled={formDisabled}
              type="submit"
              onClick={(): void => setWizardMode(true)}
              color="secondary"
            >
              Continuer (AD)
            </Button>
          )}

          <Button
            className={classes.button}
            type="submit"
            disabled={formDisabled}
            variant="outlined"
            color="secondary"
          >
            Terminer
          </Button>
        </form>
      </FormContext>
    </div>
  );
}

export default AdSetForm;
