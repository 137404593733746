import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { AdSet } from '../queries';

interface Age {
  min: number;
  max: number;
}

function getLabel(age: null | Age): string {
  if (!age || (age.min === 0 && age.max === 100)) {
    return '';
  }
  return `Entre ${age.min} et ${age.max} ans`;
}

export default function AgeSummary(): JSX.Element {
  const { watch } = useFormContext<AdSet>();

  const age = watch('criteria.age') as Age | null;

  const ageLabel = useMemo(() => getLabel(age), [age]);

  return <span>{ageLabel}</span>;
}
