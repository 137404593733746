import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ALL_DEPARTMENTS, Departement } from '../../shared/utils/departements';
import { AdSet, LocalisationZoneEnum } from '../queries';

type MUIAutocompleteChangeEvent = ChangeEvent<{}>;

interface Props {
  name: string;
  type: string;
}

function DepartementsInput(props: Props): JSX.Element {
  const { name, type } = props;
  const {
    register,
    unregister,
    setValue,
    triggerValidation,
    formState,
    watch,
  } = useFormContext<AdSet>();

  const departments = ((watch(name) || []) as string[]).map((c) => {
    return ALL_DEPARTMENTS.find(({ code }) => c === code);
  }) as Departement[];

  useEffect(() => {
    register({ name });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  async function handleChange(
    _: MUIAutocompleteChangeEvent,
    value: Departement[]
  ): Promise<void> {
    await setValue(
      name,
      value.map((val) => val.code)
    );

    if (formState.isSubmitted) {
      await triggerValidation(name);
    }
  }

  return (
    <Autocomplete
      multiple
      id={`${name}-tags-outlined`}
      options={ALL_DEPARTMENTS}
      getOptionLabel={(department: Departement): string =>
        `${department.name} (${department.code})`
      }
      value={departments}
      filterSelectedOptions
      onChange={handleChange}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          variant="outlined"
          label={`Départements${
            type !== LocalisationZoneEnum.Custom
              ? ` à ${/added/.test(name) ? 'ajouter' : 'retirer'} du ciblage ${
                  type === LocalisationZoneEnum.Client ? 'client' : 'français'
                }`
              : ''
          }`}
          margin="normal"
          fullWidth
        />
      )}
    />
  );
}
export default DepartementsInput;
