export enum LangEnum {
  fr = 'fr',
  en = 'en',
  it = 'it',
  es = 'es',
  de = 'de',
}

export enum CountryEnum {
  FR = 'FR',
  EN = 'GB',
  IT = 'IT',
  ES = 'ES',
  DE = 'DE',
  BE = 'BE',
}

export const LabelByLang: Record<LangEnum, string> = {
  [LangEnum.fr]: 'Français',
  [LangEnum.en]: 'Anglais',
  [LangEnum.it]: 'Italien',
  [LangEnum.es]: 'Espagnol',
  [LangEnum.de]: 'Allemand',
};
export const LabelByCountry: Record<CountryEnum, string> = {
  [CountryEnum.FR]: 'France',
  [CountryEnum.EN]: 'Angleterre',
  [CountryEnum.IT]: 'Italie',
  [CountryEnum.ES]: 'Espagne',
  [CountryEnum.DE]: 'Allemagne',
  [CountryEnum.BE]: 'Belgique',
};
