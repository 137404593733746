import { useMutation, useQuery } from '@apollo/react-hooks';
import React from 'react';

import { useEditingActions } from '../manager/editing-store';
import AdSetForm from './ad-set-form';
import {
  AdSetWithoutIds,
  GET_ADSET,
  GET_ADSETS_BY_CAMPAIGN_ID,
  GetAdSetResult,
  SAVE_ADSET,
  SaveAdSetParams,
} from './queries';

interface Props {
  id: string;
}

function ModifyAdSet(props: Props): JSX.Element | null {
  const { id } = props;
  const stopEditing = useEditingActions((actions) => actions.stopEditing);
  const setLoading = useEditingActions((actions) => actions.setLoading);

  // Retrieve data of ad set to edit, if applicable
  const { data } = useQuery<GetAdSetResult>(GET_ADSET, {
    skip: !id, // if ID is not provided, we're creating a new ad set
    variables: { id },
  });

  const [saveAdSet] = useMutation<void, SaveAdSetParams>(SAVE_ADSET, {
    refetchQueries: [
      {
        query: GET_ADSETS_BY_CAMPAIGN_ID,
        variables: { campaignId: data && data.adSet.campaignId },
      },
    ],
  });

  async function handleSubmit(input: AdSetWithoutIds): Promise<void> {
    if (!data) {
      return;
    }

    setLoading(true);

    const { startDate, endDate, criteria } = input;

    await saveAdSet({
      variables: {
        id,
        input: {
          campaignId: data.adSet.campaignId,
          ...input,
          startDate: startDate.toUTCString(),
          endDate: endDate.toUTCString(),
          criteria,
        },
      },
    })
      .then(() => {
        stopEditing();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return data ? (
    <AdSetForm
      campaignId={data.adSet.campaignId}
      adSet={data.adSet}
      onSubmit={handleSubmit}
    />
  ) : null;
}

export default ModifyAdSet;
