import { apiDomain } from '../../environment';
import { fetchJson } from './utils';

const fileApiUrl = `https://file.${apiDomain}/v1/file`;

export interface ImageInfo {
  width: number;
  height: number;
  src: string;
}

export async function getImageInfo(file: File): Promise<ImageInfo> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onerror = (): void => {
      reject(new DOMException('Bad file, noob.'));
    };

    img.onload = (): void => {
      const { width, height, src } = img;
      resolve({ width, height, src });
    };

    img.src = URL.createObjectURL(file);
  });
}

async function readFile(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = (): void => {
      reader.abort();
      reject(new DOMException('Bad file, noob.'));
    };

    reader.onload = (): void => {
      resolve(reader.result as ArrayBuffer);
    };

    reader.readAsArrayBuffer(file);
  });
}

interface FileRegistrationResult {
  uploadUrl: string;
  successUrl: string;
  fileId: string;
}

async function registerFile(file: File): Promise<FileRegistrationResult> {
  const extMatches = /\.(\w+)$/.exec(file.name);

  return fetchJson<FileRegistrationResult>(fileApiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify({
      fileExtension: extMatches && extMatches.length > 0 ? extMatches[1] : '',
      contentType: file.type,
      public: true,
    }),
  });
}

async function uploadToGCS(
  uploadUrl: string,
  fileType: string,
  buffer: ArrayBuffer
): Promise<void> {
  return fetch(uploadUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': fileType,
    },
    mode: 'cors',
    body: buffer,
  }).then((response) => {
    if (!response.ok) {
      throw response;
    }
  });
}

interface GetPublicUrlResult {
  fileId: string;
  publicUrl: string;
}

async function getPublicUrl(successUrl: string): Promise<string> {
  return fetchJson<GetPublicUrlResult>(successUrl, { method: 'POST' }).then(
    ({ publicUrl }) => publicUrl
  );
}

export async function uploadFile(file: File): Promise<string> {
  const { uploadUrl, successUrl } = await registerFile(file);

  const buffer = await readFile(file);
  await uploadToGCS(uploadUrl, file.type, buffer);

  return getPublicUrl(successUrl);
}

export async function createImageElem(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = (): void => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
}
