import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { ShortLabelBySpotEnum, SpotEnum } from '../../queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1, 3),
      border: '1px solid #e5e5e5',
      cursor: 'pointer',
      margin: theme.spacing(0, 2, 2, 0),
      color: theme.palette.secondary.main,
      textAlign: 'center',
      transition: theme.transitions.create('background-color'),
    },
    selected: {
      backgroundColor: theme.palette.secondary.main,
      borderColor: '#fff',
      color: '#fff',
    },
    title: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
    },
  })
);

interface Props {
  spot: SpotEnum;
  selected?: boolean;
  handleClick?: (spot: SpotEnum) => void;
}

export function SpotCard(props: Props): JSX.Element {
  const { spot, selected, handleClick } = props;

  const classes = useStyles();

  function activate(): void {
    if (handleClick) {
      handleClick(spot);
    }
  }

  return (
    <div
      className={[classes.card, selected && classes.selected].join(' ')}
      onClick={activate}
    >
      <div className={classes.title}>{ShortLabelBySpotEnum[spot]}</div>
    </div>
  );
}
