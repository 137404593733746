import gql from 'graphql-tag';

import { AdSet, ADSET_FRAGMENT } from '../ad-sets/queries';
import { Ad, AD_FRAGMENT, AdContentTypeEnum, SpotEnum } from '../ads/queries';
import { Campaign } from '../campaigns/queries';
import { FilterSettings } from '../manager/filter-store';

export interface GetCampaignFilters {
  temporalities?: string;
}

export const resourceMap = [
  {
    resourceId: SpotEnum.VerticalBannerLaRuche,
    resourceTitle: SpotEnum.VerticalBannerLaRuche,
  },
  {
    resourceId: SpotEnum.BannerLaRuche,
    resourceTitle: SpotEnum.BannerLaRuche,
  },
  {
    resourceId: SpotEnum.VerticalBannerWizbii,
    resourceTitle: SpotEnum.VerticalBannerWizbii,
  },
  {
    resourceId: SpotEnum.BannerWizbii,
    resourceTitle: SpotEnum.BannerWizbii,
  },
  {
    resourceId: SpotEnum.JobNativeAdWizbii,
    resourceTitle: SpotEnum.JobNativeAdWizbii,
  },
  {
    resourceId: SpotEnum.MenuEntryWizbii,
    resourceTitle: SpotEnum.MenuEntryWizbii,
  },
  {
    resourceId: SpotEnum.JobNativeSidebarAdWizbii,
    resourceTitle: SpotEnum.JobNativeSidebarAdWizbii,
  },
  {
    resourceId: SpotEnum.LandingWizbii,
    resourceTitle: SpotEnum.LandingWizbii,
  },
  {
    resourceId: SpotEnum.HelpNativeAdFibii,
    resourceTitle: SpotEnum.HelpNativeAdFibii,
  },
  {
    resourceId: SpotEnum.TopBarAdWizbii,
    resourceTitle: SpotEnum.TopBarAdWizbii,
  },
];

const LIGHT_CAMPAIGN_FRAGMENT = gql`
  fragment LightCampaignFragment on Campaign {
    id @export(as: "id")
    name
    adSets(campaignId: $campaignId)
      @rest(
        type: "[AdSet]"
        path: "/ad_sets?campaignIds={exportVariables.id}"
      ) {
      ...AdSetFragment
      ads @rest(type: "[Ad]", path: "/ads?adSetIds={exportVariables.adSetId}") {
        ...AdFragment
      }
    }
  }
  ${ADSET_FRAGMENT}
  ${AD_FRAGMENT}
`;

export interface GetAllResult {
  campaigns: (Campaign & {
    adSets: (AdSet & { ads: Ad<AdContentTypeEnum>[] })[];
  })[];
}

export const GET_ALL = gql`
  query GetCampaigns($temporalities: String!) {
    campaigns(temporalities: $temporalities)
      @rest(type: "[Campaign]", path: "/campaigns?{args}") {
      ...LightCampaignFragment
    }
  }
  ${LIGHT_CAMPAIGN_FRAGMENT}
`;

export function buildGetAllQuery(filters: FilterSettings): GetCampaignFilters {
  const variables: GetCampaignFilters = {};

  if (filters.temporalities.length > 0) {
    variables.temporalities = filters.temporalities.join(',');
  }

  return variables;
}
