import 'date-fns'; // eslint-disable-line
import React, { ChangeEvent, useEffect } from 'react';
import {
  Chip,
  createStyles,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { AdSet, LocalisationZoneEnum } from '../queries';
import { Client } from '../../campaigns/queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldsetWrapper: {
      display: 'block',
      marginTop: theme.spacing(4),
    },
    radioWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
    },
    dep: {
      marginRight: theme.spacing(0.5),
    },
    depWrapper: {
      marginRight: theme.spacing(2),
    },
  })
);

interface Props {
  campaignClient: Client | null;
  name: string;
}

function LocalisationZoneInput(props: Props): JSX.Element {
  const { name, campaignClient } = props;
  const classes = useStyles();
  const {
    register,
    unregister,
    setValue,
    triggerValidation,
    formState,
    watch,
  } = useFormContext<AdSet>();

  const localisationZone = watch(name) as LocalisationZoneEnum;

  useEffect(() => {
    register({ name }, { required: true });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  async function handleChange(
    evt: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const zone = evt.target.value as LocalisationZoneEnum;
    await setValue(name, zone);

    if (formState.isSubmitted) {
      await triggerValidation(name);
    }
  }

  return (
    <FormControl component="fieldset">
      <RadioGroup
        name={name}
        className={classes.radioWrapper}
        onChange={handleChange}
        value={localisationZone}
        row
      >
        <div className={classes.depWrapper}>
          <FormControlLabel
            disabled={
              !(campaignClient && campaignClient.departments) || undefined
            }
            value={LocalisationZoneEnum.Client}
            control={<Radio color="secondary" />}
            label="Départment(s) du client"
            labelPlacement="end"
          />

          {campaignClient &&
            campaignClient.departments &&
            campaignClient.departments.length &&
            campaignClient.departments.map((dep: string) => {
              return (
                <Chip
                  className={classes.dep}
                  size="small"
                  key={dep}
                  label={dep}
                />
              );
            })}
        </div>

        <FormControlLabel
          value={LocalisationZoneEnum.France}
          control={<Radio color="secondary" />}
          label="Toute la France"
          labelPlacement="end"
        />
        <FormControlLabel
          value={LocalisationZoneEnum.International}
          control={<Radio color="secondary" />}
          label="Internationale"
          labelPlacement="end"
        />
        <FormControlLabel
          value={LocalisationZoneEnum.Custom}
          control={<Radio color="secondary" />}
          label="Personnalisée"
          labelPlacement="end"
        />
      </RadioGroup>
    </FormControl>
  );
}
export default LocalisationZoneInput;
