import { Action, action, createContextStore } from 'easy-peasy';

import { isProdPlatform } from '../environment';

export interface PreviewStoreModel {
  currentPreview?: string;

  showPreview: Action<PreviewStoreModel, string>;
  clearPreview: Action<PreviewStoreModel>;
}

export const previewModel: PreviewStoreModel = {
  currentPreview: undefined,

  showPreview: action((state, imageUrl) => {
    state.currentPreview = imageUrl;
  }),

  clearPreview: action((state) => {
    state.currentPreview = undefined;
  }),
};

export const previewStore = createContextStore(previewModel, {
  name: 'PreviewStore',
  devTools: !isProdPlatform,
});

export const {
  Provider: PreviewStoreProvider,
  useStoreActions: usePreviewActions,
  useStoreState: usePreviewState,
} = previewStore;
