import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { SiteBySpot, SpotEnum } from '../../queries';
import { SiteCard } from './site-card';
import { SpotCard } from './spot-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
  })
);

export function SpotPreview(): JSX.Element {
  const classes = useStyles();

  const formMethods = useFormContext();
  const fieldName = 'spot';

  const { watch, register, unregister } = formMethods;

  const spot = watch(fieldName) as SpotEnum;

  useEffect(() => {
    register({ name: fieldName }, { required: true });
    return (): void => unregister([fieldName]);
  }, [register, unregister, fieldName]);

  return (
    <div className={classes.wrapper}>
      <SiteCard site={SiteBySpot[spot]} selected={true} />

      <SpotCard spot={spot} selected={true} />
    </div>
  );
}
