import { KeyboardEvent } from 'react';

const ERROR_MESSAGES: Record<string, string> = {
  required: 'Champ requis',
  validDate: 'Date invalide',
};

export function getErrorMessage(error?: { type: string }): string | undefined {
  return error && ERROR_MESSAGES[error.type];
}

export function validDate(date: Date): boolean {
  return !Number.isNaN(date.getTime());
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformDates(value: any, key?: number | string): any {
  if (
    typeof key === 'string' &&
    typeof value === 'string' &&
    /(^d|D)ate($|[^a-z])/.test(key)
  ) {
    return new Date(value);
  }
}

export function formatBigValue(value: number): string {
  const ranges = [
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'G' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' },
  ];

  for (let i = 0; i < ranges.length; i++) {
    if (value >= ranges[i].divider) {
      return `${(value / ranges[i].divider).toFixed(2)} ${ranges[i].suffix}`;
    }
  }
  return value.toString();
}

export function preventEnterSubmit(e: KeyboardEvent): void {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
}

export async function fetchJson<T>(
  input: RequestInfo,
  init?: RequestInit
): Promise<T> {
  return fetch(input, init).then(async (response) => {
    if (!response.ok) {
      throw response;
    }
    return response.json();
  });
}
