import { Action, action, createContextStore } from 'easy-peasy';

import { isProdPlatform } from '../environment';

export enum TemporalityEnum {
  Past = 'past',
  Current = 'current',
  Future = 'future',
}

export interface FilterSettings {
  temporalities: TemporalityEnum[];
  search?: string;
}

const defaultSettings: FilterSettings = {
  temporalities: [TemporalityEnum.Current, TemporalityEnum.Future],
};

export interface FilterStoreModel {
  currentSettings: FilterSettings;

  setSettings: Action<FilterStoreModel, FilterSettings>;
  setTemporalities: Action<FilterStoreModel, TemporalityEnum[]>;
  setSearchValue: Action<FilterStoreModel, string>;
}

export const filterModel: FilterStoreModel = {
  currentSettings: defaultSettings,

  setSettings: action((state, settings) => {
    state.currentSettings = settings;
  }),
  setTemporalities: action((state, values) => {
    state.currentSettings.temporalities = values;
  }),
  setSearchValue: action((state, value) => {
    state.currentSettings.search = value;
  }),
};

export const filterStore = createContextStore(filterModel, {
  name: 'FilterStore',
  devTools: !isProdPlatform,
});

export const {
  Provider: FilterStoreProvider,
  useStoreActions: useFilterActions,
  useStoreState: useFilterState,
} = filterStore;
