import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useMemo } from 'react';

import { Stats } from '../ad-sets/queries';
import { formatBigValue } from '../shared/utils/utils';
import StatLabel from './stats/stat-label';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adWrapper: {
      color: theme.palette.grey['600'],
    },
    adSetWrapper: {},
  })
);

interface Props {
  stats?: Stats;
}

function buildStatsView(stats?: Stats): StatsView {
  if (!stats) {
    return {
      views: {
        value: 0,
        format: '',
      },
      clicks: {
        value: 0,
        format: '',
      },
      ratio: {
        value: 0,
        format: '',
      },
    };
  }

  const viewsCount = stats.views;
  const clicksCount = stats.clicks;
  const ratio = (clicksCount / viewsCount) * 100;

  return {
    views: {
      value: viewsCount,
      format: formatBigValue(viewsCount),
    },
    clicks: {
      value: clicksCount,
      format: formatBigValue(clicksCount),
    },
    ratio: {
      value: ratio,
      format: `${ratio.toFixed(3)}%`,
    },
  };
}

interface StatsView {
  views: {
    format: string;
    value: number;
  };
  clicks: {
    format: string;
    value: number;
  };
  ratio: {
    format: string;
    value: number;
  };
}

export default function AdStats({ stats }: Props): JSX.Element {
  const classes = useStyles();

  const view = useMemo(() => buildStatsView(stats), [stats]);

  return (
    <Grid container className={classes.adWrapper}>
      <Grid item xs={4}>
        <StatLabel
          title={`${view.views.value}`}
          value={view.views.format}
          showIcon={false}
        />
      </Grid>
      <Grid item xs={4}>
        <StatLabel
          title={`${view.clicks.value}`}
          value={view.clicks.format}
          showIcon={false}
        />
      </Grid>
      <Grid item xs={4}>
        <StatLabel
          title={`${view.ratio.value}`}
          value={view.ratio.format}
          showIcon={false}
        />
      </Grid>
    </Grid>
  );
}
