import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';

import TextInput from '../inputs/text-input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
  })
);

function TopBarAdGroup(): JSX.Element {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="textHTML">Texte (HTML accepté)</InputLabel>

        <TextInput name="content.textHTML" required={true} />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="buttonText">Texte du bouton</InputLabel>
        <TextInput name="content.buttonText" required={true} />
      </FormControl>
    </div>
  );
}

export default TopBarAdGroup;
