import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { useEditingState } from '../../../manager/editing-store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonSpacer: {
      marginRight: theme.spacing(2),
      marginLeft: 'auto',
    },
    submitButton: {
      width: '120px',
    },
    wrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

function EditDraftFormControls(): JSX.Element {
  const classes = useStyles();

  const formDisabled = useEditingState((state) => state.loading);

  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.submitButton}
        color="secondary"
        type="submit"
        variant="contained"
        disabled={formDisabled}
      >
        Enregistrer
      </Button>
    </div>
  );
}

export default EditDraftFormControls;
