import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';
import EventIcon from '@material-ui/icons/Event';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PublicIcon from '@material-ui/icons/Public';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import React, { useState } from 'react';

import { Client } from '../campaigns/queries';
import ActiveInput from '../shared/inputs/active-input';
import DateRangePicker from '../shared/inputs/date-range-picker';
import RangeInput from '../shared/inputs/range-input';
import BankInput from './inputs/bank-input';
import CountriesInput from './inputs/countries-input';
import DepartementsInput from './inputs/departements-input';
import LanguageInput from './inputs/language-input';
import LocalisationZoneInput from './inputs/localisation-zone-input';
import LoyaltyInput from './inputs/loyalty-input';
import PriorityInput from './inputs/priority-input';
import { LocalisationZoneEnum } from './queries';
import AgeSummary from './setting-previews/age-summary';
import DateSummary from './setting-previews/date-summary';
import BanksSummary from './setting-previews/diverse-summary';
import LocalisationSummary from './setting-previews/localisation-summary';
import LoyaltySummary from './setting-previews/loyalty-summary';
import PanelHeader from './setting-previews/panel-header';
import PrioritySummary from './setting-previews/priority-summary';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    borderBottom: 0,

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      '&:last-child': {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
      },
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.secondary.main,
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    departmentWrapper: {},
    tragetingWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
  })
);

interface Props {
  campaignClient: Client | null;
  localisationZone: LocalisationZoneEnum;
}

function AdSetSettings(props: Props): JSX.Element {
  const { campaignClient, localisationZone } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>('panel_1');

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ): void => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded === 'panel_1'}
        onChange={handleChange('panel_1')}
      >
        <PanelHeader
          label="Dates"
          icon={<EventIcon />}
          summary={<DateSummary />}
        />

        <ExpansionPanelDetails>
          <DateRangePicker />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === 'panel_2'}
        onChange={handleChange('panel_2')}
      >
        <PanelHeader
          label="Priorisation"
          icon={<GpsFixedIcon />}
          summary={<PrioritySummary />}
        />
        <ExpansionPanelDetails>
          <PriorityInput name="priority" />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === 'panel_3'}
        onChange={handleChange('panel_3')}
      >
        <PanelHeader
          icon={<MonetizationOnIcon />}
          label="Ciblage par loyalty"
          summary={<LoyaltySummary client={campaignClient} />}
        />
        <ExpansionPanelDetails>
          <LoyaltyInput
            campaignClient={campaignClient}
            name="criteria.loyaltyPackageId"
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === 'panel_4'}
        onChange={handleChange('panel_4')}
      >
        <PanelHeader
          label="Localisation"
          icon={<PublicIcon />}
          summary={<LocalisationSummary />}
        />
        <ExpansionPanelDetails>
          <LocalisationZoneInput
            campaignClient={campaignClient}
            name="criteria.localisation.zone"
          />

          <div className={classes.departmentWrapper}>
            {[
              LocalisationZoneEnum.Client,
              LocalisationZoneEnum.Custom,
            ].includes(localisationZone) && (
              <DepartementsInput
                name="criteria.localisation.addedDepartments"
                type={localisationZone}
              />
            )}
            {[
              LocalisationZoneEnum.Client,
              LocalisationZoneEnum.France,
            ].includes(localisationZone) && (
              <DepartementsInput
                name="criteria.localisation.substractedDepartments"
                type={localisationZone}
              />
            )}
            {localisationZone === LocalisationZoneEnum.International && (
              <CountriesInput name="criteria.localisation.countries" />
            )}
            <LanguageInput name="criteria.userLanguages" />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === 'panel_6'}
        onChange={handleChange('panel_6')}
      >
        <PanelHeader
          label="Ciblage par âge"
          icon={<SupervisorAccountIcon />}
          summary={<AgeSummary />}
        />
        <ExpansionPanelDetails>
          <RangeInput name="criteria.age" />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === 'panel_7'}
        onChange={handleChange('panel_7')}
      >
        <PanelHeader
          label="Ciblage divers"
          icon={<EmojiSymbolsIcon />}
          summary={<BanksSummary />}
        />

        <ExpansionPanelDetails>
          <div>
            <Typography>Banques à retirer du ciblage :</Typography>
            <BankInput name="criteria.banks.substractedBanks" />
          </div>

          <div className={classes.tragetingWrapper}>
            <ActiveInput
              name="criteria.drivingLicense"
              required={false}
              color={'primary'}
            />
            <Typography>
              Cibler uniquement les personnes qui n&apos;ont pas le permis de
              conduire
            </Typography>
          </div>

          <div className={classes.tragetingWrapper}>
            <ActiveInput
              name="criteria.student"
              required={false}
              color={'primary'}
            />
            <Typography>Cibler uniquement les étudiants</Typography>
          </div>

          <div className={classes.tragetingWrapper}>
            <ActiveInput
              name="criteria.studentLoan"
              required={false}
              color={'primary'}
            />
            <Typography>
              Cibler uniquement les personnes intéressées par un prêt étudiant
            </Typography>
          </div>

          <div className={classes.tragetingWrapper}>
            <ActiveInput
              name="criteria.mutualHealth"
              required={false}
              color={'primary'}
            />
            <Typography>
              Cibler uniquement les personnes qui n&apos;ont pas de mutuelle
              santé
            </Typography>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default AdSetSettings;
