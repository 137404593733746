import {
  Collapse,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  Theme,
} from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderRounded from '@material-ui/icons/FolderRounded';
import React, { useState } from 'react';

import ListAdSet from '../ad-sets/list-ad-set';
import CampaignMenuItems from '../shared/utils/menus/campaign-menu-items';
import ContextMenu from '../shared/utils/menus/context-menu';
import { CampaignWithClient } from './queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: theme.palette.grey[100],
      height: theme.spacing(4.75),
      display: 'flex',
    },
    header: {
      paddingLeft: theme.spacing(0),
    },
    nested: {
      paddingLeft: theme.spacing(0),
    },
    editWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: theme.spacing(1),
      marginTop: '3px',
    },
    editButton: {
      padding: theme.spacing(1),
    },
    listOpen: {
      borderLeft: `1px solid ${theme.palette.secondary.main}`,
    },
    gridLabel: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
    },
    gridIcon: {
      marginRight: theme.spacing(1),
      opacity: 0.9,
      fontSize: theme.spacing(2.4),
    },
    rowIcon: {
      marginRight: theme.spacing(2),
      height: theme.spacing(2.875),
    },
  })
);

interface Props {
  campaign: CampaignWithClient;
}

function CampaignItem({ campaign }: Props): JSX.Element {
  const classes = useStyles();

  const [listOpen, setListOpen] = useState(false);

  function handleListClick(): void {
    setListOpen(!listOpen);
  }

  return (
    <div className={listOpen ? classes.listOpen : undefined}>
      <ContextMenu items={<CampaignMenuItems campaign={campaign} />}>
        <ListItem button onClick={handleListClick} className={classes.wrapper}>
          <Grid container alignItems="center">
            <Grid item xs={5} container alignItems="center">
              <span className={classes.rowIcon}>
                {listOpen ? <FolderOpenIcon /> : <FolderRounded />}
              </span>

              <b>{campaign.name}</b>
            </Grid>
            <Grid item xs={7}>
              <div className={classes.gridLabel}>
                <FaceIcon className={classes.gridIcon} />
                <span>{campaign.client.name}</span>
              </div>
            </Grid>
          </Grid>

          <Divider light />
        </ListItem>
      </ContextMenu>

      <Collapse in={listOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div className={classes.nested}>
            <ListAdSet campaignId={campaign.id} />
          </div>
        </List>
      </Collapse>
      <Divider />
    </div>
  );
}

export default CampaignItem;
