import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { AdSet } from '../queries';

function formatDate(date: Date): string {
  if (isNaN(date.valueOf())) {
    return '';
  }

  const options = {
    day: 'numeric',
    month: 'long',
  };

  return new Intl.DateTimeFormat('fr-FR', options).format(date);
}

export default function DateSummary(): JSX.Element {
  const { watch } = useFormContext<AdSet>();

  const startDate = watch('startDate') as Date;
  const endDate = watch('endDate') as Date;

  const formattedStartDate = useMemo(() => formatDate(startDate), [startDate]);
  const formattedEndDate = useMemo(() => formatDate(endDate), [endDate]);

  const isValid = useMemo(() => {
    return !isNaN(startDate.valueOf()) && !isNaN(endDate.valueOf());
  }, [startDate, endDate]);

  return (
    <span>{isValid && `Du ${formattedStartDate} au ${formattedEndDate}`}</span>
  );
}
