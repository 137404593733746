import {
  Button,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import CheckboxInput from '../../shared/inputs/checkbox-input';
import SelectTypeInput from '../../shared/inputs/select-type-input';
import {
  Ad,
  AdContentTypeEnum,
  AdDraft,
  AdWithoutIds,
  SpotEnum,
} from '../queries';
import { SpotInput } from './inputs/spot-input';
import { SpotPreview } from './inputs/spot-preview';
import TextInput from './inputs/text-input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
    wrapper: {
      marginBottom: theme.spacing(3),
    },
    header: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);
export const AllowedTypesBySpot: Record<SpotEnum, AdContentTypeEnum[]> = {
  [SpotEnum.VerticalBannerLaRuche]: [AdContentTypeEnum.Image],
  [SpotEnum.BannerLaRuche]: [AdContentTypeEnum.Image],
  [SpotEnum.VerticalBannerWizbii]: [AdContentTypeEnum.Image],
  [SpotEnum.BannerWizbii]: [AdContentTypeEnum.Image],
  [SpotEnum.JobNativeAdWizbii]: [
    AdContentTypeEnum.JobNativeAd,
    AdContentTypeEnum.Image,
  ],
  [SpotEnum.JobNativeSidebarAdWizbii]: [
    AdContentTypeEnum.JobNativeSidebarAd,
    AdContentTypeEnum.Image,
  ],
  [SpotEnum.TopBarAdWizbii]: [
    AdContentTypeEnum.TopBarAd,
    AdContentTypeEnum.Image,
  ],
  [SpotEnum.MenuEntryWizbii]: [AdContentTypeEnum.MenuEntry],
  [SpotEnum.LandingWizbii]: [AdContentTypeEnum.Article],
  [SpotEnum.HelpNativeAdFibii]: [AdContentTypeEnum.HelpNativeAd],
};

interface Props {
  ad?: Ad<AdContentTypeEnum>;
  submitDraft?: (ad: AdDraft) => void;
}

function AdCommonFields(props: Props): JSX.Element {
  const { ad, submitDraft } = props;
  const classes = useStyles();

  const formMethods = useFormContext();

  const { getValues } = formMethods;

  function handleDraftSubmit(): void {
    const values = getValues({ nest: true }) as AdWithoutIds;
    // draft doesn't allow content
    delete values.content;

    if (submitDraft) {
      submitDraft(values as AdDraft);
    }
  }

  return (
    <div className={classes.wrapper}>
      {ad ? <SpotPreview /> : <SpotInput />}

      {!ad && (
        <div className={classes.header}>
          <Typography color={'textSecondary'}>
            2. Remplir les informations de la publicité
          </Typography>

          <Button
            variant="outlined"
            color="primary"
            size={'small'}
            onClick={handleDraftSubmit}
            startIcon={<ScheduleIcon />}
          >
            Remplir plus tard
          </Button>
        </div>
      )}

      <SelectTypeInput
        disabled={!!ad && !!ad.content}
        fieldName="content.type"
        label="Type"
      />

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="name">Nom (optionnel)</InputLabel>
        <TextInput name="name" />
      </FormControl>

      <FormControl className={classes.input} fullWidth variant="filled">
        <InputLabel htmlFor="link">Lien</InputLabel>
        <TextInput name="content.link" required={true} />
      </FormControl>

      <CheckboxInput
        disabled={false}
        name="content.mustAddLoginToken"
        label="Ajouter un login token à ce lien"
      />

      <CheckboxInput
        disabled={false}
        name="content.blank"
        label="Ouvrir le lien dans un nouvel onglet"
      />
    </div>
  );
}

export default AdCommonFields;
