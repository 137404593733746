import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CountryEnum, LabelByCountry } from '../../shared/utils/lang';
import { AdSet } from '../queries';

interface Props {
  name: string;
}

function CountriesInput(props: Props): JSX.Element {
  const { name } = props;
  const { register, unregister, setValue, watch } = useFormContext<AdSet>();

  const countries = (watch(name) || []) as CountryEnum[];

  useEffect(() => {
    register({ name });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  async function handleChange(
    _: ChangeEvent<{}>,
    value: CountryEnum[]
  ): Promise<void> {
    await setValue(name, value);
  }

  const availableCountries = Object.values(CountryEnum);

  return (
    <Autocomplete
      multiple
      id={`${name}-tags`}
      options={availableCountries}
      getOptionLabel={(country: CountryEnum): string => LabelByCountry[country]}
      value={countries}
      filterSelectedOptions
      onChange={handleChange}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          variant="outlined"
          label="Pays"
          margin="normal"
          fullWidth
        />
      )}
    />
  );
}

export default CountriesInput;
