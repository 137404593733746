import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { usePreviewActions } from '../../../manager/preview-store';
import { SiteEnum, SpotBySite, SpotEnum } from '../../queries';
import { AllowedTypesBySpot } from '../ad-common-fields';
import { SiteCard } from './site-card';
import { SpotCard } from './spot-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sites: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(1),
    },
    spots: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    wrapper: {
      marginBottom: theme.spacing(2),
    },
  })
);

const sites = [SiteEnum.Wizbii, SiteEnum.Fibii, SiteEnum.LaRuche];

export function SpotInput(): JSX.Element {
  const classes = useStyles();

  const formMethods = useFormContext();

  const { watch, setValue, register, unregister } = formMethods;
  const fieldName = 'spot';

  const [selectedSite, setSelectedSite] = useState<SiteEnum>(SiteEnum.Wizbii);

  const clearPreview = usePreviewActions((actions) => actions.clearPreview);
  const spotInputValue = watch(fieldName) as SpotEnum;

  useEffect(() => {
    register({ name: fieldName }, { required: true });
    return (): void => unregister([fieldName]);
  }, [register, unregister, fieldName]);

  async function handleSiteChange(site: SiteEnum): Promise<void> {
    if (site !== selectedSite) {
      setSelectedSite(site);
      const defaultSpot = SpotBySite[site][0];
      await setValue('content.type', AllowedTypesBySpot[defaultSpot][0]);
      await setValue(fieldName, defaultSpot);
      clearPreview();
    }
  }

  async function handleSpotChange(spot: SpotEnum): Promise<void> {
    await setValue('content.type', AllowedTypesBySpot[spot][0]);
    await setValue(fieldName, spot);
    clearPreview();
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.sites}>
        {sites.map((site, index) => {
          return (
            <SiteCard
              key={index}
              site={site}
              selected={selectedSite === site}
              handleClick={handleSiteChange}
            />
          );
        })}
      </div>

      <div className={classes.spots}>
        {SpotBySite[selectedSite].map((spot, index) => {
          return (
            <SpotCard
              key={index}
              spot={spot}
              selected={spotInputValue === spot}
              handleClick={handleSpotChange}
            />
          );
        })}
      </div>
    </div>
  );
}
