import { useMutation, useQuery } from '@apollo/react-hooks';
import React from 'react';

import { useEditingActions } from '../manager/editing-store';
import { useFilterState } from '../manager/filter-store';
import CampaignForm from './campaign-form';
import {
  buildGetCampaignQuery,
  CampaignWithoutId,
  GET_CAMPAIGN,
  GET_CAMPAIGNS,
  GetCampaignResult,
  SAVE_CAMPAIGN,
  SaveCampaignParams,
} from './queries';

interface Props {
  id: string;
}

function ModifyCampaign(props: Props): JSX.Element | null {
  const { id } = props;
  const stopEditing = useEditingActions((actions) => actions.stopEditing);
  const setLoading = useEditingActions((actions) => actions.setLoading);

  // Retrieve data of campaign to edit, if applicable
  const { data } = useQuery<GetCampaignResult>(GET_CAMPAIGN, {
    skip: !id, // if ID is not provided, we're creating a new campaign
    variables: { id },
  });

  const filterSettings = useFilterState((state) => state.currentSettings);

  const [saveCampaign] = useMutation<void, SaveCampaignParams>(SAVE_CAMPAIGN, {
    refetchQueries: [
      {
        query: GET_CAMPAIGNS,
        variables: buildGetCampaignQuery(filterSettings),
      },
    ],
  });

  async function handleSubmit(input: CampaignWithoutId): Promise<void> {
    setLoading(true);
    await saveCampaign({ variables: { id, input } })
      .then(() => {
        stopEditing();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return data ? (
    <CampaignForm campaign={data.campaign} onSubmit={handleSubmit} />
  ) : null;
}

export default ModifyCampaign;
