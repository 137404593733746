import {
  Action,
  action,
  Computed,
  computed,
  createStore,
  createTypedHooks,
} from 'easy-peasy';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { isProdPlatform } from './environment';

export interface JwtTokens {
  token: string;
  refreshToken: string;
}

export interface Jwt {
  type: string;
  'user-id': string;
  roles: string[];
  'app-id': string;
  exp: number;
  iat: number;
  'external-services-package-id': string;
}

export interface StoreModel {
  tokens?: JwtTokens;

  token: Computed<StoreModel, string | undefined>;
  decodedToken: Computed<StoreModel, Jwt | undefined>;
  refreshToken: Computed<StoreModel, string | undefined>;
  isLoggedIn: Computed<StoreModel, boolean>;

  logIn: Action<StoreModel, JwtTokens>;
  logOut: Action<StoreModel>;
}

const TOKENS_COOKIE_KEY = 'wizbii_bo';

function getDomain(hostname: string): string {
  const hostnameSplit = hostname.split('.');
  const hostnameSplitLength = hostnameSplit.length;
  if (hostnameSplit[0] === 'localhost') {
    return 'localhost';
  }
  return `${hostnameSplit[hostnameSplitLength - 2]}.${
    hostnameSplit[hostnameSplitLength - 1]
  }`;
}
const cookieAttributes: Cookies.CookieAttributes = {
  domain: getDomain(window.location.hostname),
};
const rawTokens = Cookies.get(TOKENS_COOKIE_KEY); // read cookie on init

const model: StoreModel = {
  // === STATE ===
  tokens: rawTokens ? (JSON.parse(rawTokens) as JwtTokens) : undefined,

  // === COMPUTED PROPERTIES ===
  token: computed(({ tokens }) => tokens && tokens.token),
  decodedToken: computed(({ token }) => (token ? jwtDecode(token) : undefined)),
  refreshToken: computed(({ tokens }) => tokens && tokens.refreshToken),
  isLoggedIn: computed(({ token }) => !!token),

  // === ACTIONS ===
  logIn: action((state, tokens) => {
    state.tokens = tokens;
    Cookies.set(TOKENS_COOKIE_KEY, tokens, cookieAttributes);
  }),

  logOut: action((state) => {
    state.tokens = undefined;
    Cookies.remove(TOKENS_COOKIE_KEY);
  }),
};

export const store = createStore(model, {
  name: 'GlobalStore',
  devTools: !isProdPlatform,
});

export const {
  useStoreActions: useGlobalActions,
  useStoreState: useGlobalState,
} = createTypedHooks<StoreModel>();
