import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import {
  ResourceTypeEnum,
  useEditingActions,
  useEditingState,
} from '../manager/editing-store';
import AdForm from './forms/ad-form';
import {
  AdDraft,
  AdWithoutIds,
  CREATE_AD,
  CreateAdDraftParams,
  CreateAdParams,
  CreateAdResult,
  GET_ADS_BY_ADSET_ID,
  SpotEnum,
  TopBarAdType,
} from './queries';

interface Props {
  parentId: string;
}

function CreateAd(props: Props): JSX.Element | null {
  const { parentId } = props;
  const startEditing = useEditingActions((actions) => actions.startEditing);
  const stopEditing = useEditingActions((actions) => actions.stopEditing);
  const editingState = useEditingState((state) => state.editingStatus);
  const setLoading = useEditingActions((actions) => actions.setLoading);

  const [saveAd] = useMutation<CreateAdResult, CreateAdParams>(CREATE_AD, {
    refetchQueries: [
      {
        query: GET_ADS_BY_ADSET_ID,
        variables: { adSetId: parentId },
      },
    ],
  });
  const [saveAdDraft] = useMutation<CreateAdResult, CreateAdDraftParams>(
    CREATE_AD,
    {
      refetchQueries: [
        {
          query: GET_ADS_BY_ADSET_ID,
          variables: { adSetId: parentId },
        },
      ],
    }
  );

  function reOpenDrawer(delay = 300): void {
    setTimeout(() => {
      startEditing({
        type: ResourceTypeEnum.Ad,
        parentId,
      });
    }, delay);
  }

  function openCreatedAd(id: string): void {
    setTimeout(() => {
      startEditing({
        type: ResourceTypeEnum.Ad,
        id,
      });
    }, 500);
  }

  async function handleDraft(input: AdDraft): Promise<void> {
    if (!parentId) {
      throw new Error('Programming error');
    }

    setLoading(true);

    await saveAdDraft({
      variables: {
        input: {
          adSetId: parentId,
          ...input,
          active: true,
        },
      },
    })
      .then(() => {
        stopEditing();
        reOpenDrawer(500);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleSubmit(input: AdWithoutIds): Promise<void> {
    if (!parentId) {
      throw new Error('Programming error');
    }

    setLoading(true);

    if (
      input.spot === SpotEnum.TopBarAdWizbii &&
      input.content &&
      (input.content as TopBarAdType).textHTML
    ) {
      input = {
        ...input,
        content: {
          ...input.content,
          textHTML: sanitizeHtml((input.content as TopBarAdType).textHTML, {
            allowedTags: ['b', 'i', 'em', 'strong', 'a', 'span'],
            allowedAttributes: {
              a: ['href'],
              '*': ['style'],
            },
          }),
        },
      };
    }

    await saveAd({
      variables: {
        input: {
          adSetId: parentId,
          ...input,
          active: true,
        },
      },
    })
      .then((response) => {
        if (response.data) {
          if (editingState && editingState.reOpen) {
            reOpenDrawer();
          } else if (editingState && editingState.editAfterCreation) {
            const id = response.data.ad.id;
            openCreatedAd(id);
          }
        }
        stopEditing();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return <AdForm onSubmit={handleSubmit} onDraftSubmit={handleDraft} />;
}

export default CreateAd;
