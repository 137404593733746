import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { AdSet, BankCriteria } from '../queries';

function getLabel(
  criteria: BankCriteria | null,
  student: boolean | null,
  license: boolean | null,
  studentLoan: boolean | null
): string {
  const text = 'Personnalisé';

  if (student || license || studentLoan) {
    return text;
  }

  if (!criteria) {
    return '';
  }

  if (criteria.substractedBanks && criteria.substractedBanks.length > 0) {
    return text;
  }

  return '';
}

export default function DiverseSummary(): JSX.Element {
  const { watch } = useFormContext<AdSet>();

  const criteria = watch('criteria.banks') as BankCriteria | null;
  const student = watch('criteria.student') as boolean | null;
  const drivingLicense = watch('criteria.drivingLicense') as boolean | null;
  const studentLoan = watch('criteria.studentLoan') as boolean | null;

  const label = useMemo(
    () => getLabel(criteria, student, drivingLicense, studentLoan),
    [criteria, student, drivingLicense, studentLoan]
  );

  return <span>{label}</span>;
}
