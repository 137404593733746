import RUNTIME_VARS from './vars.json';

// === BUILD-TIME VARIABLES ===

export const appId = 'admanager';
export const isDeployed = !!process.env.REACT_APP_CI_COMMIT_SHA;
export const version = process.env.REACT_APP_CI_COMMIT_SHA || 'dev';

// === RUNTIME VARIABLES ===

type RuntimeVars = keyof typeof RUNTIME_VARS; // construct strict union type from keys of JSON object (doesn't work with array)
type RuntimeEnv = Record<RuntimeVars, string>;
type CustomWindow = typeof window & { env: RuntimeEnv };

// Retrieve injected vars or use fallback vars in dev
const vars: RuntimeEnv = isDeployed
  ? (window as CustomWindow).env
  : {
      PLATFORM: 'local',
      API_PLATFORM: 'staging',
      MEDIA_API_BASE_URL: '',
    };

// Export runtime vars for use in the app
export const { PLATFORM: platform, API_PLATFORM: apiPlatform } = vars;

// === COMPUTED RUNTIME VARIABLES ===

export const isProdPlatform = ['prod', 'staging'].includes(platform);

export const apiDomain =
  apiPlatform === 'prod' ? 'wizbii.com' : `${apiPlatform}.k8s.wizbii.us`;

export const mediaApiBaseUrl =
  vars.MEDIA_API_BASE_URL || `https://m-api.${apiDomain}`;

export const siteUrl =
  platform === 'local'
    ? ''
    : `${window.location.protocol}//${window.location.host}`;

export const ssoRedirectUrl = `${
  siteUrl || 'https://localhost.0' // sso-internal and Auth API have strict domain rules
}/login`;
