import { useMutation } from '@apollo/react-hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import React, { forwardRef, Ref } from 'react';

import {
  AdSet,
  DELETE_ADSET,
  DeleteAdSetParams,
  GET_ADSETS_BY_CAMPAIGN_ID,
} from '../../../ad-sets/queries';
import {
  ResourceTypeEnum,
  useEditingActions,
} from '../../../manager/editing-store';
import { ContextMenuItem } from './context-menu';

interface Props {
  adSet: AdSet;
  closeMenu?: (e: React.MouseEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuIcon: {
      marginRight: theme.spacing(1.2),
      fontSize: theme.spacing(2.6),
    },
  })
);

const AdsetMenuItems = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { adSet, closeMenu } = props;

  const classes = useStyles();
  const startEditing = useEditingActions((actions) => actions.startEditing);

  const [deleteAdSet] = useMutation<void, DeleteAdSetParams>(DELETE_ADSET, {
    variables: { id: adSet.id },
    refetchQueries: [
      {
        query: GET_ADSETS_BY_CAMPAIGN_ID,
        variables: { campaignId: adSet.campaignId },
      },
    ],
  });

  const handleClose = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    if (closeMenu) {
      closeMenu(event);
    }
  };

  async function handleDeletion(
    event: React.MouseEvent<HTMLElement>
  ): Promise<void> {
    handleClose(event);
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Êtes-vous sûr(e) de vraiment vouloir supprimer la campagne ?'
      )
    ) {
      await deleteAdSet();
    }
  }

  return (
    <div ref={ref}>
      <ContextMenuItem
        onClick={(evt): void => {
          handleClose(evt);
          startEditing({
            type: ResourceTypeEnum.AdSet,
            id: adSet.id,
          });
        }}
      >
        <EditIcon className={classes.menuIcon} />
        Éditer l&apos;ensemble
      </ContextMenuItem>

      <ContextMenuItem
        onClick={(evt): void => {
          handleClose(evt);
          startEditing({
            type: ResourceTypeEnum.Ad,
            parentId: adSet.id,
          });
        }}
      >
        <ImageIcon className={classes.menuIcon} />
        Ajouter une publicité
      </ContextMenuItem>

      <ContextMenuItem onClick={handleDeletion}>
        <DeleteIcon className={classes.menuIcon} />
        Supprimer l&apos;ensemble
      </ContextMenuItem>
    </div>
  );
});

export default AdsetMenuItems;
