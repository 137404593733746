import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { usePreviewActions } from '../../../manager/preview-store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {},
    preview: {
      height: '52px',
      width: 'auto',
      border: '2px solid',
      borderRadius: '3px',
      borderColor: theme.palette.secondary.main,
      cursor: 'pointer',
    },
  })
);

interface Props {
  imageUrl: string;
}

export function MiniThumb(props: Props): JSX.Element {
  const { imageUrl } = props;

  const classes = useStyles();

  const showPreview = usePreviewActions((actions) => actions.showPreview);

  return (
    <div
      className={classes.wrapper}
      onClick={(): void => {
        showPreview(imageUrl);
      }}
    >
      <img alt="" className={classes.preview} src={imageUrl} />
    </div>
  );
}
