import DateFnsUtils from '@date-io/date-fns';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { fr } from 'date-fns/locale';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { AdSet } from '../../ad-sets/queries';
import { getErrorMessage, validDate } from '../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datetime: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    datePicker: {
      marginRight: theme.spacing(2),
      flex: '0 0 50%',
    },
    timePicker: {
      flex: '0 0 30%',
    },
  })
);

function DateRangePicker(): JSX.Element {
  const classes = useStyles();
  const {
    register,
    unregister,
    setValue,
    triggerValidation,
    errors,
    formState,
    watch,
  } = useFormContext<Partial<AdSet>>();

  const startDate = watch('startDate') as MaterialUiPickersDate;
  const endDate = watch('endDate') as MaterialUiPickersDate;

  useEffect(() => {
    register(
      { name: 'startDate' },
      {
        required: true,
        validate: { validDate },
      }
    );

    register(
      { name: 'endDate' },
      {
        required: true,
        validate: { validDate },
      }
    );

    return (): void => unregister(['startDate', 'endDate']);
  }, [register, unregister]);

  function createChangeHandler(fieldName: 'startDate' | 'endDate') {
    return async (date: MaterialUiPickersDate): Promise<void> => {
      await setValue(fieldName, date ? date : undefined);

      if (formState.isSubmitted) {
        await triggerValidation(fieldName);
      }
    };
  }

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
        <div className={classes.datetime}>
          <KeyboardDatePicker
            id="start-date"
            className={classes.datePicker}
            name="startDate"
            label="Date de début"
            format="dd/MM/yyyy"
            margin="normal"
            required
            maxDate={endDate}
            value={startDate}
            onChange={createChangeHandler('startDate')}
            error={!!errors.startDate}
            helperText={getErrorMessage(errors.startDate)}
          />

          <KeyboardTimePicker
            className={classes.timePicker}
            margin="normal"
            name="startDate"
            id="start-time"
            label="Heure de début"
            value={startDate}
            ampm={false}
            onChange={createChangeHandler('startDate')}
          />
        </div>
        <div className={classes.datetime}>
          <KeyboardDatePicker
            className={classes.datePicker}
            id="end-date"
            name="endDate"
            label="Date de fin"
            format="dd/MM/yyyy"
            margin="normal"
            required
            minDate={startDate}
            value={endDate}
            onChange={createChangeHandler('endDate')}
            error={!!errors.endDate}
            helperText={getErrorMessage(errors.endDate)}
          />

          <KeyboardTimePicker
            margin="normal"
            className={classes.timePicker}
            id="end-time"
            name="endDate"
            label="Heure de fin"
            value={endDate}
            ampm={false}
            onChange={createChangeHandler('endDate')}
          />
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
}
export default DateRangePicker;
