export interface Bank {
  value: string;
  label: string;
}

export const ALL_BANKS = [
  { value: 'banque-postale', label: 'Banque Postale' },
  { value: 'bnp-paribas', label: 'BNP Paribas' },
  { value: 'credit-agricole', label: 'Crédit Agricole' },
  { value: 'societe-generale', label: 'Société Générale' },
  { value: 'caisse-epargne', label: "Caisse d'Epargne" },
  { value: 'banque-populaire', label: 'Banque Populaire' },
  { value: 'credit-mutuel', label: 'Crédit Mutuel' },
  { value: 'cic', label: 'CIC' },
  { value: 'credit-du-nord', label: 'Crédit du Nord' },
  { value: 'lcl', label: 'LCL' },
];
