import 'date-fns'; // eslint-disable-line
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import {
  Ad,
  AdContentTypeEnum,
  LabelByAdContentTypeEnum,
  SpotEnum,
} from '../../ads/queries';
import { AllowedTypesBySpot } from '../../ads/forms/ad-common-fields';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
    hiddenInput: {
      display: 'none',
    },
    wrapper: {
      margin: theme.spacing(5),
    },
    box: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  })
);

type MUISelectChangeEvent = ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

interface InputProps {
  fieldName: string;
  label: string;
  disabled: boolean;
}

function SelectTypeInput({
  fieldName,
  label,
  disabled,
}: InputProps): JSX.Element {
  const classes = useStyles();

  const {
    register,
    unregister,
    setValue,
    triggerValidation,
    formState,
    watch,
  } = useFormContext<Ad<AdContentTypeEnum>>();

  const inputValue = watch(fieldName) as AdContentTypeEnum | undefined;
  const spotValue = watch('spot') as SpotEnum | undefined;

  const options = useMemo(() => {
    if (!spotValue) {
      return [];
    }
    return AllowedTypesBySpot[spotValue];
  }, [spotValue]);

  useEffect(() => {
    register({ name: fieldName }, { required: true });
    return (): void => unregister([fieldName]);
  }, [register, unregister, fieldName]);

  async function handleChange(evt: MUISelectChangeEvent): Promise<void> {
    const value = evt.target.value as string;
    await setValue(fieldName, value);

    if (formState.isSubmitted) {
      await triggerValidation(fieldName);
    }
  }

  return (
    <FormControl
      className={
        fieldName === 'content.type' &&
        Object.keys(AdContentTypeEnum).length < 2
          ? classes.hiddenInput
          : classes.input
      }
      fullWidth
      variant="filled"
    >
      <InputLabel htmlFor={fieldName}>
        {inputValue ? label : 'Séléctionner un type de publicité'}
      </InputLabel>
      <Select
        disabled={disabled}
        labelId={fieldName}
        id={fieldName}
        name={fieldName}
        onChange={handleChange}
        value={inputValue ? inputValue : ''}
      >
        <MenuItem value={''} disabled>
          Séléctionner un type de publicité
        </MenuItem>

        {inputValue && !options.includes(inputValue) && (
          <MenuItem value={inputValue}>
            {LabelByAdContentTypeEnum[inputValue]}
          </MenuItem>
        )}

        {options.map((key, index) => (
          <MenuItem key={index} value={key}>
            {LabelByAdContentTypeEnum[key as AdContentTypeEnum]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectTypeInput;
