import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form';

import { useEditingActions } from '../../manager/editing-store';
import {
  usePreviewActions,
  usePreviewState,
} from '../../manager/preview-store';
import { preventEnterSubmit } from '../../shared/utils/utils';
import {
  Ad,
  AdContentTypeEnum,
  AdDraft,
  AdWithoutIds,
  SpotEnum,
} from '../queries';
import AdCommonFields from './ad-common-fields';
import CreateAdFormControls from './controls/create-ad-form-controls';
import EditAdFormControls from './controls/edit-ad-form-controls';
import EditDraftFormControls from './controls/edit-draft-form-controls';
import ArticleGroup from './form-groups/article-group';
import HelpNativeAdGroup from './form-groups/help-native-ad-group';
import JobNativeAdGroup from './form-groups/job-native-ad-group';
import JobNativeSidebarAdGroup from './form-groups/job-native-sidebar-ad-group';
import MenuEntryGroups from './form-groups/menu-entry-group';
import TopBarAdGroup from './form-groups/top-bar-ad-group';
import DropMediaInput, { RatioPerSpot } from './inputs/drop-media-input';
import { Thumb } from './inputs/thumb';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
    wrapper: {
      padding: theme.spacing(5),
      overflowY: 'scroll',
    },
    box: {
      marginBottom: theme.spacing(2),
    },
  })
);

const defaultValues = {
  spot: SpotEnum.BannerWizbii,
  'content.type': AdContentTypeEnum.Image,
  'content.blank': false,
  'content.mustAddLoginToken': false,
};
const defaultDraftValues = {
  'content.blank': false,
  'content.mustAddLoginToken': false,
};

interface Props {
  ad?: Ad<AdContentTypeEnum>;

  onDraftSubmit?: (ad: AdDraft) => Promise<void>;
  onSubmit(input: AdWithoutIds): Promise<void>;
}

function AdForm(props: Props): JSX.Element {
  const { ad, onSubmit, onDraftSubmit } = props;
  const classes = useStyles();

  let def;

  if (ad && !ad.content) {
    // if ad is a draft (= no content), we merge with default values
    def = { ...ad, ...defaultDraftValues };
  } else {
    def = ad || defaultValues;
  }

  const formMethods = useForm<Ad<AdContentTypeEnum>>({
    defaultValues: def,
  });

  const { watch, handleSubmit, reset, formState } = formMethods;

  const spotInputValue = watch('spot') as SpotEnum;
  const typeInputValue = watch('content.type') as AdContentTypeEnum | undefined;

  const setDirty = useEditingActions((actions) => actions.setDirty);
  const currentPreview = usePreviewState((state) => state.currentPreview);
  const clearPreview = usePreviewActions((actions) => actions.clearPreview);

  useEffect(() => {
    if (formState.dirty) {
      setDirty();
    }
  }, [formState, setDirty]);

  useEffect(() => {
    clearPreview();
    reset();
  }, [reset, clearPreview]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.box}>
        <Typography variant={'h5'} className={classes.input}>
          Publicité
          {!!ad && !ad.content && (
            <Typography color={'textSecondary'}>Brouillon</Typography>
          )}
        </Typography>
        {!ad && (
          <Typography color={'textSecondary'} className={classes.input}>
            1. Définir l&apos;emplacement de la publicité
          </Typography>
        )}
      </div>

      <FormContext {...formMethods}>
        <form
          className={classes.box}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          onKeyPress={preventEnterSubmit}
        >
          <AdCommonFields ad={ad} submitDraft={onDraftSubmit} />

          {typeInputValue === AdContentTypeEnum.Image && (
            <DropMediaInput
              required={true}
              spot={(ad && ad.spot) || defaultValues.spot}
            />
          )}
          {typeInputValue === AdContentTypeEnum.JobNativeAd && (
            <JobNativeAdGroup spot={spotInputValue} />
          )}
          {typeInputValue === AdContentTypeEnum.JobNativeSidebarAd && (
            <JobNativeSidebarAdGroup />
          )}
          {typeInputValue === AdContentTypeEnum.MenuEntry && (
            <MenuEntryGroups spot={spotInputValue} />
          )}
          {typeInputValue === AdContentTypeEnum.HelpNativeAd && (
            <HelpNativeAdGroup />
          )}
          {typeInputValue === AdContentTypeEnum.TopBarAd && <TopBarAdGroup />}
          {typeInputValue === AdContentTypeEnum.Article && <ArticleGroup />}

          {!!typeInputValue && !ad && <CreateAdFormControls />}

          {!!typeInputValue &&
            ad &&
            (ad.content ? (
              <EditAdFormControls ad={ad} />
            ) : (
              <EditDraftFormControls />
            ))}
        </form>
      </FormContext>

      <Thumb
        spot={(ad && ad.spot) || defaultValues.spot}
        inputValue={currentPreview || ''}
        ratio={RatioPerSpot[(ad && ad.spot) || defaultValues.spot]}
      />
    </div>
  );
}

export default AdForm;
