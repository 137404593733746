import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import React from 'react';

import { ResourceTypeEnum, useEditingActions } from '../manager/editing-store';
import SearchBar from '../shared/filters/search-bar';
import TemporalityFilter from '../shared/filters/temporality-filter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: {
      display: 'flex',
      padding: '3rem 0 0 0',
      alignItems: 'center',
    },
    title: {
      margin: 0,
    },
    action: {
      marginLeft: 'auto',
    },
    marginRight: {
      marginRight: theme.spacing(1.6),
    },
  })
);

function HeaderCampaign(): JSX.Element {
  const classes = useStyles();
  const startEditing = useEditingActions((actions) => actions.startEditing);

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.marginRight}>
        <SearchBar />
      </div>

      <TemporalityFilter />

      <Button
        className={classes.action}
        variant="contained"
        type="submit"
        color="secondary"
        onClick={(): void => {
          startEditing({ type: ResourceTypeEnum.Campaign });
        }}
        endIcon={<PostAddIcon />}
      >
        Créer une campagne
      </Button>
    </div>
  );
}

export default HeaderCampaign;
