import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { ResourceHeaderProps } from 'react-big-calendar';

import { SpotColor } from '../../ads/ad-item';
import { SpotEnum } from '../../ads/queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customRbcEvent: {
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.main,
      height: '1.5rem',
      fontSize: '.7rem',
    },
  })
);

export function CustomRessourceHeader(
  props: ResourceHeaderProps
): JSX.Element | null {
  const { label } = props;
  const classes = useStyles();

  return (
    <span
      style={{ backgroundColor: SpotColor[label as SpotEnum] }}
      className={classes.customRbcEvent}
    >
      {label}
    </span>
  );
}
