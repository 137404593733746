import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      boxShadow: theme.shadows[5],
      transform: 'translate(-50%,-50%)',
    },
    iframe: {
      width: 900,
      height: 600,
      border: 0,
    },
  })
);

interface Props {
  adId: string;
  open: boolean;
  onCloseHandler: () => void;
}

function AdStatsModal(props: Props): JSX.Element {
  const { adId, open, onCloseHandler } = props;
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onCloseHandler}>
      <div className={classes.modal}>
        <iframe
          className={classes.iframe}
          title="Ad Stats"
          src={`https://wizbii.domo.com/embed/card/1949800849?enable=title,table,summary,drill,filter,picker&pfilters=[{%22column%22:%22ad_id%22,%22dataType%22:%22STRING%22,%22operand%22:%22IN%22,%22values%22:[%22${adId}%22]}]`}
        />
      </div>
    </Modal>
  );
}

export default AdStatsModal;
