import InputBase from '@material-ui/core/InputBase';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React, { useRef } from 'react';

import { useFilterActions } from '../../manager/filter-store';

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    width: '100%',
  },
  preIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    border: '1px solid rgba(0,0,0,0.12)',
    borderRadius: '4px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 6),
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: 140,
      '&:focus': {
        width: 200,
      },
    },
  },
}));

export default function SearchBar(): JSX.Element {
  const setSearchValue = useFilterActions((actions) => actions.setSearchValue);

  const classes = useStyles();
  const ref = useRef<HTMLInputElement>(null);

  function handleChange(): void {
    if (ref.current) {
      setSearchValue(ref.current.value);
    }
  }

  return (
    <div className={classes.search}>
      <div className={classes.preIcon}>
        <SearchIcon />
      </div>
      <InputBase
        inputRef={ref}
        onKeyUp={handleChange}
        placeholder="Rechercher..."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
      />
    </div>
  );
}
