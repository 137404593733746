import 'date-fns'; // eslint-disable-line
import React, { ChangeEvent, useEffect } from 'react';
import {
  createStyles,
  FormControl,
  makeStyles,
  Slider,
  Theme,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { AdSet } from '../../ad-sets/queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginLeft: 0,
      marginRight: 0,
    },
    slider: {
      marginTop: theme.spacing(6.25),
    },
  })
);

interface InputProps {
  name: string;
}

function valuetext(value: number): string {
  return `${value}an`;
}

function RangeInput({ name }: InputProps): JSX.Element {
  const classes = useStyles();

  const {
    register,
    unregister,
    setValue,
    triggerValidation,
    formState,
    watch,
  } = useFormContext<Partial<AdSet>>();

  const inputValue = watch(name) as { min: number; max: number } | undefined;

  useEffect(() => {
    register({ name }, { required: false });
    return (): void => unregister([name]);
  }, [register, unregister, name]);

  async function handleChange(
    evt: ChangeEvent<{}>,
    newValue: number | number[]
  ): Promise<void> {
    if (!(newValue instanceof Array)) {
      return;
    }

    await setValue(name, { min: newValue[0], max: newValue[1] });

    if (formState.isSubmitted) {
      await triggerValidation(name);
    }
  }

  return (
    <FormControl className={classes.input} fullWidth variant="filled">
      <div className={classes.slider}>
        <Slider
          name={name}
          onChange={handleChange}
          valueLabelDisplay="on"
          defaultValue={[
            (inputValue && inputValue.min) || 0,
            (inputValue && inputValue.max) || 100,
          ]}
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
        />
      </div>
    </FormControl>
  );
}
export default RangeInput;
