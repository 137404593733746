import { createStyles, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    inputs: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    skeleton: {
      transform: 'scale(1)',
      margin: '0 0 1.25rem',
    },
  })
);

export function CalendarSkeleton(): JSX.Element | null {
  const classes = useStyles();

  return (
    <div>
      <Skeleton
        animation="wave"
        className={classes.skeleton}
        width={'10rem'}
        height={'2.5rem'}
      />
      <Skeleton
        animation="wave"
        className={classes.skeleton}
        width={'100%'}
        height={'3.5rem'}
      />

      <div className={classes.inputs}>
        <Skeleton
          animation="wave"
          className={classes.skeleton}
          width={'13.437rem'}
          height={'3.5rem'}
        />
        <Skeleton
          animation="wave"
          className={classes.skeleton}
          width={'13.437rem'}
          height={'3.5rem'}
        />
        <Skeleton
          animation="wave"
          className={classes.skeleton}
          width={'13.437rem'}
          height={'3.5rem'}
        />
      </div>

      <Skeleton
        animation="wave"
        className={classes.skeleton}
        width={'100%'}
        height={'40rem'}
      />
    </div>
  );
}
