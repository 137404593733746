import { Drawer } from '@material-ui/core';
import React from 'react';

import CreateAdSet from '../ad-sets/create-ad-set';
import ModifyAdSet from '../ad-sets/modify-ad-set';
import CreateAd from '../ads/create-ad';
import ModifyAd from '../ads/modify-ad';
import CreateCampaign from '../campaigns/create-campaign';
import ModifyCampaign from '../campaigns/modify-campaign';
import {
  ResourceTypeEnum,
  useEditingActions,
  useEditingState,
} from './editing-store';

const CREATE_COMPS = {
  [ResourceTypeEnum.AdSet]: CreateAdSet,
  [ResourceTypeEnum.Ad]: CreateAd,
};

const MODIFY_COMPS = {
  [ResourceTypeEnum.Campaign]: ModifyCampaign,
  [ResourceTypeEnum.AdSet]: ModifyAdSet,
  [ResourceTypeEnum.Ad]: ModifyAd,
};

function EditingDrawer(): JSX.Element {
  const editingStatus = useEditingState((state) => state.editingStatus);
  const stopEditing = useEditingActions((actions) => actions.stopEditing);

  function handleClose(): void {
    if (editingStatus && editingStatus.dirty) {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          "Êtes-vous sûr(e) de vouloir fermer l'éditeur ? Vos modifications n'ont pas été enregistrées."
        )
      ) {
        stopEditing();
      }
    } else {
      stopEditing();
    }
  }

  function renderChild(): JSX.Element | null {
    if (!editingStatus) {
      return null;
    }

    if (editingStatus.id) {
      const Modify = MODIFY_COMPS[editingStatus.type];
      return <Modify id={editingStatus.id} />;
    }

    if (
      editingStatus.parentId &&
      editingStatus.type !== ResourceTypeEnum.Campaign
    ) {
      const Create = CREATE_COMPS[editingStatus.type];
      return <Create parentId={editingStatus.parentId} />;
    }

    return <CreateCampaign />;
  }

  return (
    <Drawer anchor="right" open={!!editingStatus} onClose={handleClose}>
      {renderChild()}
    </Drawer>
  );
}

export default EditingDrawer;
