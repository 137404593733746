import {
  createStyles,
  Grid,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import ScheduleTwoToneIcon from '@material-ui/icons/ScheduleTwoTone';
import React, { ChangeEvent, MouseEvent } from 'react';

import { Stats } from '../ad-sets/queries';
import { ResourceTypeEnum, useEditingActions } from '../manager/editing-store';
import AdMenuItems from '../shared/utils/menus/ad-menu-items';
import ContextMenu from '../shared/utils/menus/context-menu';
import AdStats from './ad-stats';
import AdActiveForm from './forms/ad-active-form';
import { Ad, AdContentTypeEnum, LabelBySpotEnum, SpotEnum } from './queries';

export const SpotColor: Record<SpotEnum, string> = {
  [SpotEnum.VerticalBannerLaRuche]: '#e8a3f4',
  [SpotEnum.BannerLaRuche]: '#e8a3f4',
  [SpotEnum.VerticalBannerWizbii]: '#8681fe',
  [SpotEnum.BannerWizbii]: '#8681fe',
  [SpotEnum.JobNativeAdWizbii]: '#8681fe',
  [SpotEnum.JobNativeSidebarAdWizbii]: '#8681fe',
  [SpotEnum.MenuEntryWizbii]: '#8681fe',
  [SpotEnum.LandingWizbii]: '#8681fe',
  [SpotEnum.TopBarAdWizbii]: '#8681fe',
  [SpotEnum.HelpNativeAdFibii]: '#43ceff',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: theme.spacing(4.75),
      display: 'flex',
    },
    lowOpacity: {
      opacity: 0.66,
    },
    header: {
      paddingLeft: theme.spacing(0),
    },
    nested: {
      paddingLeft: theme.spacing(0),
    },
    name: {},
    indent: {
      paddingLeft: theme.spacing(6),
    },
    adButton: {
      marginLeft: theme.spacing(6),
    },
    greyLabel: {
      color: theme.palette.grey['600'],
    },
    activeInput: {
      opacity: 0.8,
    },
    rowIcon: {
      marginRight: theme.spacing(2),
      height: theme.spacing(2.875),
    },
    menuIcon: {
      marginRight: theme.spacing(1.2),
      fontSize: theme.spacing(2.6),
    },
  })
);

interface Props {
  ad: Ad<AdContentTypeEnum>;
  adSetId: string;
  active: boolean;
  stats?: Stats;
}

function AdItem({ ad, adSetId, stats, active }: Props): JSX.Element {
  const classes = useStyles();
  const startEditing = useEditingActions((actions) => actions.startEditing);

  return (
    <>
      <ContextMenu items={<AdMenuItems ad={ad} adSetId={adSetId} />}>
        <ListItem
          button
          className={[classes.wrapper, !ad.content && classes.lowOpacity].join(
            ' '
          )}
          onClick={(evt: MouseEvent): void => {
            evt.preventDefault();
            startEditing({
              type: ResourceTypeEnum.Ad,
              id: ad.id,
            });
          }}
        >
          <Grid container className={classes.header} alignItems="center">
            <Grid
              className={classes.indent}
              item
              xs={4}
              container
              alignItems="center"
            >
              <span className={classes.rowIcon}>
                <ImageIcon style={{ color: SpotColor[ad.spot] }} />
              </span>

              <b>{LabelBySpotEnum[ad.spot] || ad.spot}</b>
            </Grid>

            <Grid
              item
              xs={1}
              className={classes.activeInput}
              onClick={(e: ChangeEvent<EventTarget>): void =>
                e.stopPropagation()
              }
            >
              {ad.content ? (
                <AdActiveForm active={active} ad={ad} />
              ) : (
                <Tooltip title={'Brouillon'} placement={'right'}>
                  <ScheduleTwoToneIcon />
                </Tooltip>
              )}
            </Grid>

            <Grid item xs={3} className={classes.greyLabel}>
              {ad.name}
            </Grid>

            <Grid item xs={4}>
              {ad.content && <AdStats stats={stats} />}
            </Grid>
          </Grid>
        </ListItem>
      </ContextMenu>
    </>
  );
}

export default AdItem;
